.main-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
}
.home-logo {
  display: none;
}
.home-page .inner-page-logo {
  display: none;
}
.home-page .home-logo,
.inner-page-logo {
  display: inline-block;
}
.home-page .link-item.nav-link {
  display: none !important;
}
.search-opt-items.nav-link img {
  position: absolute;
  top: -5px;
  right: 20px;
}
.search-opt-items .dropdown .dropdown-menu {
  right: 0;
  left: unset;
  top: -15px;
  border: medium none;
  padding: 0;
  background-color: transparent;
  width: 0px;
  transition: all 0.4s;
}
.search-opt-items .dropdown .dropdown-menu.show {
  width: 220px;
}
.search-opt-items .form-group .btn {
  position: absolute;
  right: 0;
  padding: 0;
  top: 0px;
  border: medium none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-opt-items .form-group button img {
  position: static;
}
.search-opt-items .dropdown-toggle::after {
  display: none;
}
.search-opt-items {
  position: relative;
}
.search-opt-items .form-group .form-cotrol {
  width: 100%;
  background-color: #eaeaea;
  border: 2px solid #fff;
  height: auto;
  padding: 10px;
  border-radius: 30px;
  padding-right: 50px;
}
.search-opt-items .form-group .form-cotrol:hover,
.search-opt-items .form-group .form-cotrol:active,
.search-opt-items .form-group .form-cotrol:focus {
  outline: 0 none;
  box-shadow: unset;
  border: 2px solid #fff;
}
.search-opt-items .form-group button img {
  left: 0;
  top: 0;
}
.main-header .header-top-bar {
  background-color: #008ec7;
  padding: 8px 0;
  position: relative;
  z-index: 1021;
}
.main-header .navbar {
  padding: 6px 0 6px;
  background-color: #0e9dd6;
  align-items: center;
}
.main-header .navbar-toggler-icon {
  background-image: url("../../assets/images/Menu.svg");
  width: 24px;
  height: 24px;
}
.main-header .navbar-toggler {
  padding: 0 0 0 4px;
}
.navbar-nav {
  width: 100%;
  margin-inline-start: auto;
}
.main-header .navbar-brand {
  padding: 0;
}
.main-header .navbar-toggler,
.main-header .navbar-toggler:hover,
.main-header .navbar-toggler:active,
.main-header .navbar-toggler:focus {
  outline: 0 none;
  box-shadow: unset;
  border: medium none;
}
.nav-flag {
  display: inline-block;
  background-color: #00aff4;
  padding: 4px 6px;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
  margin-inline-start: 3px;
}
.social-links-top .link-item .nav-link {
  font-size: 12px;
  background-color: #fff;
  border-radius: 50%;
  padding: 1px 4px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-links-top .link-item + .link-item {
  margin-inline-start: 8px;
}
.language-options .link-item .nav-link {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 8px;
}
.language-options .link-item .btn-primary,
.language-options .link-item .btn-primary:hover,
.language-options .link-item .btn-primary:focus,
.language-options .link-item .btn-primary:active {
  background-color: transparent;
  border: medium none;
  box-shadow: unset;
  outline: 0 none;
}
.header-top-bar .social-links-top {
  margin-top: 0;
}
.language-options .dropdown-toggle {
  display: flex;
  align-items: center;
}
.language-options .dropdown-toggle::after {
  border: medium none;
  background-image: url("../../assets/images/contry-chevron.png");
  background-repeat: no-repeat;
  height: 8px;
  width: 12px;
  background-position: 0px 2px;
  margin-inline-start: 6px;
  transition: all 0.4s;
}
.language-options .dropdown-toggle.show::after {
  transform: rotate(-180deg);
}
.language-options .dropdown-menu .dropdown-item {
  padding: 8px 12px;
  font-size: 14px;
}
.language-options .link-item img {
  margin-inline-end: 5px;
  height: 16px;
  width: 16px;
}
.main-header .navbar .navbar-nav .nav-link.user-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 30px;
  border: 2px solid #fff;
  background-color: #f08146;
  margin-inline-start: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-icon.nav-link.user-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  display: none;
}
.main-header .navbar .navbar-nav .nav-link.user-icon img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
}
.main-header .sticky-top {
  z-index: 1020;
}
.myaccount-dropdown-menu.dropdown-menu {
  background-color: #00aff4;
  z-index: 9;
  top: 0;
  right: 0;
  width: 360px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  position: fixed !important;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  transition: transform 0.3s ease-in-out;
  left: unset;
  padding: 35px;
  height: 100vh;
}
.myaccount-links {
  height: calc(100% - 230px);
  overflow-y: auto;
}
.myaccount-links img {
  width: 32px;
}
.myaccount-dropdown-menu.dropdown-menu.show {
  transform: unset;
}
.myaccount-dropdown-menu .dropdown-item:hover,
.myaccount-dropdown-menu .dropdown-item:active,
.myaccount-dropdown-menu .dropdown-item:focus {
  outline: 0 none;
  box-shadow: unset;
  background-color: transparent;
  color: #000;
}
.account-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-header .navbar .nav-link.getin-touch-link {
  background-color: #fff;
  border-radius: 24px;
  color: #00aff4;
  font-size: 14px;
  margin-right: 12px;
  font-weight: 600;
}
.bg-green {
  background-color: rgba(63, 180, 110, 0.88);
}
.account-header .profile-picture img {
  width: 80px;
  height: 80px;
  border: 2px solid #e3e3e3;
  border-radius: 50px;
}
.filter-img-white {
  filter: brightness(0) invert(1);
  width: 93px;
  height: 30px;
}
.myaccount-dropdown-menu .dropdown-item {
  padding-inline: 0;
  margin-bottom: 20px;
}
.myaccount-dropdown-menu hr {
  border: 0.5px solid #fff;
  margin: 1.5rem 0 2.5rem;
}
.goback-btn img {
  filter: brightness(0) invert(1);
  margin-inline-end: 5px;
}
.goback-btn {
  font-size: 12px;
  font-weight: 600;
}
.myaccount-links .dropdown-item,
.myaccount-links .dropdown-item:hover,
.myaccount-links .dropdown-item:active,
.myaccount-links .dropdown-item:focus {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  background-color: transparent;
}
.main-header .navbar .nav-link {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 0.5rem 1.25rem;
}
.main-header .navbar .nav-link.notify-btn {
  border-radius: 244px;
  border: 1px solid #00aff4;
  background: #fff;
  color: #008ec7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.language-options .dropdown-menu-lg-start[data-bs-popper] {
  min-width: 7rem;
  padding: 0;
}
.language-options {
  justify-content: flex-end;
}
.dropdown .btn-success {
  padding: 0;
  background-color: transparent;
  border: medium none;
}
.dropdown-toggle.btn-success::after {
  display: none;
}
.google-login-button div[role="button"] .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  width: 22px;
  height: 22px;
}
.google-login-button div[role="button"] {
  background-color: #c9daf2;
  width: 100%;
  height: 48px;
  border: 2px solid #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px !important;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
  padding-right: 40px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000;
  text-decoration: none;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 10px 5px;
  background-size: 32px;
  text-transform: unset;
  font-family: var(--bs-btn-font-family);
}
.main-header .navbar .btn-primary {
  padding: 0.65rem 1.13rem;
  text-transform: capitalize;
  border-radius: 30px;
  border: 2px solid #fff;
  background-color: #f08146;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  transition: all 0.4s;
}
.login-modal .modal-dialog,
.email-modal .modal-dialog {
  max-width: 585px;
}
.login-modal .modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-modal .modal-content {
  border-radius: 24px;
  border: 2px solid #fff;
  background: url("../../assets/images/loginbg.webp"),
    linear-gradient(239deg, #56bee7 0%, #0096d1 100%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  max-width: 90%;
  margin: 0 auto;
}
.email-modal .modal-content {
  border-radius: 24px;
  border: 2px solid #fff;
  background: url("../../assets/images/login-bg-colored.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top right;
  margin: 0 auto;
}
.login-modal .login-content {
  padding: 30px 0 15px;
}

.login-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 25px;
  color: #fff;
}
.login-text img {
  max-width: 92px;
}
.login-button {
  width: 80%;
  max-width: 360px;
}
.login-button .btn-primary {
  width: 100%;
  height: 48px;
  border: 2px solid #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
  padding: 0 40px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000;
  text-decoration: none;
  justify-content: center;
}
.login-button-group .btn-primary img {
  left: 5px;
  top: 6px;
  width: 32px;
  height: 32px;
  position: absolute;
}

.login-button-group .login-button:first-child .btn-primary {
  background-color: #f4d5c7;
}
.login-button-group .login-button:nth-child(2) .btn-primary {
  background-color: #c9daf2;
}
.login-button-group span {
  display: block;
  width: 100%;
  max-width: 360px;
}
.login-button-group .kep-login-facebook {
  background-color: #c9daf2;
  width: 100%;
  height: 48px;
  border: 2px solid #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px !important;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
  padding: 0 40px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000;
  text-decoration: none;
  justify-content: center;
  background-image: url("../../assets/images/fb.svg");
  background-repeat: no-repeat;
  background-position: 10px 5px;
  background-size: 32px;
  text-transform: unset;
  font-family: var(--bs-btn-font-family);
}
.login-button-group .login-button:nth-child(3) .btn-primary {
  background-color: #de4a39;
}
.login-button-group .login-button:last-child .btn-primary {
  background-color: #c9daf2;
}
.terms-text,
.terms-text a {
  font-size: 9px;
  line-height: 16px;
  padding-top: 12px;
  color: #fff;
}
.terms-text {
  margin: 0px auto 0px auto;
  text-align: center;
}
.terms-text a {
  text-decoration-line: underline;
}
/* Responsive Design */
@media (min-width: 992px) {
  .language-options .dropdown-menu-lg-start[data-bs-popper] {
    left: -15px;
  }
}
@media screen and (min-width: 1200px) {
  .main-header .navbar .navbar-nav .nav-link:first-child,
  .main-header .navbar .navbar-nav .nav-link:nth-last-child(2) {
    margin-inline-start: auto;
  }
  .home-page .main-header .navbar .navbar-nav .nav-link:nth-last-child(3) {
    margin-inline-start: auto;
  }
  .language-options .link-item + .link-item {
    margin-inline-start: 20px;
  }
  .login-button .btn-primary .fa {
    margin-inline-end: 40px;
  }

  .navbar-nav {
    align-items: center;
  }
  .language-options .link-item .nav-link {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 1199.98px) {
  .main-header .navbar .nav-link {
    padding: 0.25rem 0rem;
    margin: 6px 0px;
    color: #4caacf;
  }
  .user-icon.nav-link.user-image img {
    display: block;
  }
  .main-header .navbar .nav-link.getin-touch-link {
    padding: 10px 16px;
  }
  .main-header .navbar .nav-link.user-icon {
    font-size: 12px;
    padding: 4px 15px;
    /* background-color: #fb610f; */
    color: #fff;
    border-radius: 30px;
    margin: 0 4px;
    margin-left: auto;
  }
  .main-header .navbar .nav-link.notify-btn {
    max-width: 135px;
    text-align: center;
  }
  .main-header .navbar .btn-primary {
    max-width: 120px;
    text-align-last: left;
  }
  .main-header .navbar .btn-primary {
    display: none;
    margin-left: auto;
    border: medium none !important;
  }
  .main-header .navbar-collapse {
    position: absolute;
    background-color: #fff;
    width: calc(100% - 30px);
    top: 53px;
    border-radius: 0px 0 10px 10px;
    padding-left: 15px;
    padding-bottom: 14px;
    box-shadow: 0 5.53846px 13.0769px rgba(0, 0, 0, 0.12);
    padding-top: 8px;
  }
}
@media screen and (max-width: 767.98px) {
  .social-links-top .link-item .nav-link {
    font-size: 12px;
    height: 20px;
    width: 20px;
  }
}
@media screen and (max-width: 575.98px) {
  .language-options {
    justify-content: space-evenly;
  }
  .myaccount-dropdown-menu.dropdown-menu {
    padding: 22px;
  }
}
