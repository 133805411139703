body * {
  font-family: "Poppins", sans-serif;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a,
.btn {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s;
}

img {
  width: auto;
  height: auto;
}

.wrapper {
  max-width: 1920px;
  margin-inline: auto;
}

/* Import Fonts */
/* 
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.eot");
  src: url("./assets/fonts/Poppins-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Light.woff") format("woff"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype"),
    url("./assets/fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.eot");
  src: url("./assets/fonts/Poppins-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype"),
    url("./assets/fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.eot");
  src: url("./assets/fonts/Poppins-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Medium.woff") format("woff"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype"),
    url("./assets/fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.eot");
  src: url("./assets/fonts/Poppins-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.eot");
  src: url("./assets/fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Bold.woff") format("woff"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype"),
    url("./assets/fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d3d3d3;
}

/* Common CSS Start */
@media (min-width: 1366px) {
  .container {
    max-width: 1200px;
  }
}

.owl-carousel.owl-theme .owl-nav {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.owl-carousel.owl-theme .owl-nav button.owl-prev,
.owl-carousel.owl-theme .owl-nav button.owl-next {
  background-color: #e3f2f8;
  top: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  box-shadow: 0 11px 10px 1px #008ec733;
  border-radius: 50%;
  font-size: 20px;
  position: absolute;
  color: #000;
  border: 2px solid #fff;
}

.owl-collection.owl-carousel.owl-theme .owl-nav button.owl-prev,
.owl-collection.owl-carousel.owl-theme .owl-nav button.owl-next {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: unset;
  background-repeat: no-repeat;
}

.owl-carousel.owl-theme .owl-nav button.owl-prev,
.owl-carousel.owl-theme .owl-nav button.owl-next {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px;
  font-size: 0px;
}

.owl-carousel.owl-theme .owl-nav button.owl-prev {
  background-image: url("./assets/images/arrow-right.png");
}

.owl-carousel.owl-theme .owl-nav button.owl-next {
  background-image: url("./assets/images/arrow-left.png");
}

.owl-carousel.owl-theme .owl-nav button.owl-prev {
  left: -90px;
}

.owl-carousel.owl-theme .owl-nav button.owl-next {
  right: -90px;
}

.owl-collection.owl-carousel.owl-theme .owl-nav {
  top: unset;
  transform: unset;
  bottom: 80px;
}

.owl-collection.owl-carousel.owl-theme .owl-nav button.owl-prev {
  left: unset;
  right: -110px;
}

.owl-collection.owl-carousel.owl-theme .owl-nav button.owl-next {
  right: -170px;
}

.experience-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev,
.experience-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
  height: 36px;
  width: 36px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 12px rgb(0 0 0/20%);
}

.experience-carousel .owl-carousel.owl-theme .owl-nav {
  top: -4px;
  transform: unset;
}

.bubble-loader .login-bubble-dots {
  background: #e35b26;
  animation: 1s ease-in-out infinite both inlineSwell;
  border-radius: 100%;
  display: inline-block;
  height: 12px;
  margin: 0 4px;
  width: 12px;
}

.bubble-loader .login-bubble-dots:nth-child(2) {
  animation-delay: 0.16s;
}

.bubble-loader .login-bubble-dots:nth-child(3) {
  animation-delay: 0.32s;
}

.image-group .form-control {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.wrap-info .image-group {
  width: 124px;
  margin-inline: auto;
}

.icon-group {
  position: relative;
}

.icon-group img {
  position: absolute;
  right: 10px;
  top: 42px;
  max-width: 16px;
}

.loader-relative div#preloader {
  position: relative;
  min-height: 300px;
  background-color: rgba(255, 255, 255, 0.5) !important;
  z-index: 1;
}

@keyframes inlineSwell {
  0%,
  100% {
    transform: scale(0);
  }

  25%,
  75% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }
}

.react-datepicker .react-datepicker__day {
  padding: 11px 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 50%;
  text-align: center;
  line-height: 17px;
  width: auto;
  height: auto;
  min-width: 39px;
}

.react-datepicker .react-datepicker__day-name {
  font-size: 18px;
  color: #484848;
  font-weight: 500;
  text-align: center;
  padding: 0 10px;
  min-width: 39px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #e35b26 !important;
}

.react-datepicker .react-datepicker__current-month {
  font-size: 26px;
  line-height: 38px;
  font-weight: 500;
  color: #15192c;
}

.react-datepicker .react-datepicker__navigation-icon {
  top: 14px;
}

.other-payment .google-pay-button-container button {
  width: 100%;
  margin-top: 18px;
}

/* Common CSS End */
@media screen and (max-width: 1199.98px) {
  .owl-carousel.owl-theme .owl-nav button.owl-prev {
    left: -20px;
  }

  .owl-carousel.owl-theme .owl-nav button.owl-next {
    right: -20px;
  }
}

@media screen and (max-width: 767.98px) {
  .owl-collection.owl-carousel.owl-theme .owl-nav {
    top: unset;
    transform: unset;
    bottom: 20px;
  }

  .owl-carousel.owl-theme .owl-nav button.owl-prev,
  .owl-carousel.owl-theme .owl-nav button.owl-next {
    width: 36px;
    height: 36px;
  }

  .experience-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev,
  .experience-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
    width: 26px;
    height: 26px;
    top: 7px;
  }

  .experience-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
    right: 25px;
  }

  .experience-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev {
    left: -25px;
  }
}
