.banner-section {
  background: linear-gradient(180deg, #0096d1 0%, #5ac7f1 100%);
  position: relative;
  overflow: hidden;
}

.banner-text {
  padding: 180px 0 200px 0;
  text-align: center;
  position: relative;
  z-index: 1;
}

.banner-section::before {
  position: absolute;
  left: 0;
  bottom: -40px;
  content: "";
  background-image: url("https://s3.me-south-1.amazonaws.com/testimagesbah.kidzapp.com/profilepics/1698308697464Fill-3.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  width: 100%;
  height: 100%;
  max-width: 977px;
  margin: auto;
  right: 0;
}

.left-banner-image,
.right-banner-image {
  position: absolute;
  bottom: 0;
  transform: scaleX(-1);
  z-index: -1;
}

.left-banner-image {
  left: 0;
}

.right-banner-image {
  right: 0;
}

.banner-title {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  margin-inline: auto;
  max-width: 650px;
}

.banner-title span {
  color: #f5cf20;
}

.banner-text p {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.banner-search-form {
  width: 100%;
  max-width: 540px;
  background-color: #fff;
  border-radius: 8px;
  margin-inline: auto;
  position: relative;
  z-index: 1;
}

.banner-search-form .form-group {
  margin: 0;
  position: relative;
  width: 100%;
}

.banner-search-form .form-group .form-control {
  max-width: 565px;
  padding: 20px 20px;
  width: 100%;
  border: medium none;
  border-left: 1px solid #ccc;
  border-radius: 0;
}

.banner-search-form .form-group .btn-primary {
  border: medium none;
}

.banner-search-form .dropdown-menu {
  padding: 5px;
  border-radius: 0 0 11px 11px;
  border: 0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}

.banner-search-form .dropdown-menu .dropdown-item {
  padding: 8px 12px;
  font-size: 14px;
}

.banner-search-form .form-group .dropdown-toggle {
  background-color: transparent;
  border: medium none;
  color: #010317;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  transition: all 0.4s;
  min-width: 140px;
  padding-inline-end: 31px;
}

.banner-search-form .form-group .dropdown-toggle:hover,
.banner-search-form .form-group .dropdown-toggle:active,
.banner-search-form .form-group .dropdown-toggle:focus {
  outline: 0 none;
  box-shadow: unset;
}

.banner-search-form .form-group .dropdown-toggle::after {
  border: medium none;
  background-image: url("../../../assets/images/dropdown-arrow-grey.png");
  background-repeat: no-repeat;
  height: 22px;
  width: 12px;
  background-position: -1px 9px;
  margin-inline-start: 8px;
  margin-inline-end: 7px;
  transition: all 0.4s;
}

.banner-search-form .form-group .dropdown-toggle.show::after {
  transform: rotate(-180deg);
}

.banner-search-form .form-group .search-btn {
  padding: 0;
  border-radius: 0;
  margin: 4px 5px;
  background-color: transparent;
}

.banner-search-form .form-group .search-btn img {
  transition: all 0.4s;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

@media screen and (max-width: 991.98px) {
  .banner-text::after,
  .banner-text::before {
    display: none;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 767.98px) {
  .banner-text {
    padding: 170px 0 120px 0;
    text-align: center;
  }

  .banner-title {
    font-size: 34px;
    line-height: 40px;
  }

  .banner-section .banner-text {
    height: 100vh;
  }

  .banner-section .banner-text :where(.left-banner-image, .right-banner-image) {
    width: 180px;
    bottom: 100px;
  }

  .banner-section .banner-text p {
    margin: 12px 0 35px 0;
  }
}
