.experience-carousel {
  display: block;
  margin-top: 40px;
}
body:not(.home-page) .navbar {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  background: #fff;
}
body:not(.home-page) .navbar .navbar-toggler-icon {
  filter: invert(1);
}
body:not(.home-page) .main-header .navbar .nav-link {
  color: #000;
}
body:not(.home-page) .main-header .navbar .user-icon.nav-link {
  color: #fff;
}
.experience-carousel .item li a,
.experience-carousel .item li button {
  color: #000;
  /* margin: 0 14px; */
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.5;
  background-color: transparent;
  border: medium none;
  border-bottom: 2px solid #fff;
  padding: 8px 0;
  max-height: 27px;
  overflow: hidden;
  position: relative;
}
.experience-carousel .owl-item.active {
  width: auto !important;
  display: inline-block;
}
.experience-carousel .owl-item li {
  text-align: center;
  margin-right: 40px;
}
.experience-carousel .owl-item.active li.activelist a,
.experience-carousel .owl-item.active li.activelist button {
  color: #00aff4;
  opacity: 1;
  font-weight: 700;
}
.experience-carousel .owl-item.active li.activelist:after {
  content: "";
  display: block;
  border-bottom: 2px solid #00aff4;
  padding-bottom: 5px;
}
.experience-cards {
  margin: 30px 0;
}
.activity-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  transform: translateY(5px);
  margin: 0 15px;
}
.activity-card:hover {
  transform: translateY(-5px);
}
.activity-media {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  max-height: 156px;
}
.activity-media a img {
  transition: all 0.6s;
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: cover;
}
.deal-flag img {
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: cover;
}
.experience-cards .activity-card {
  margin-bottom: 30px;
  margin-inline: 0;
}
.deal-flag {
  display: flex;
  background-image: linear-gradient(to right, #e49827, #e45b27);
  position: absolute;
  left: 0;
  padding: 6px 8px;
  border-radius: 8px 0;
}
.deal-flag span {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: 3px;
}
.rating-flag {
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  background: #fff;
  font-weight: 500;
  padding: 2px 6px;
}
.rating-flag img {
  height: 14px;
  width: 14px;
  margin-inline-end: 4px;
}
.distant-section .price-section {
  min-height: 43.8px;
}
.distant-section .activity-title.activity {
  margin-top: 0;
}
.activity-content {
  padding: 12px 16px;
  /* min-height: 155px; */
}
.activity-title.d-flex.justify-content-between a {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.activity-title {
  color: #201c1d;
  font-size: 16px;
  font-weight: 600;
  min-height: 20px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 15px 0 0 0;
}
.activity-title a {
  color: #201c1d;
  margin: 0;
}
.activity-title .book-now-btn {
  border-radius: 24px;
  border: 1px solid #00aff4;
  background: #fff;
  padding: 8px 20px;
  display: inline-block;
  color: #00aff4;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.activity-title .book-now-btn:hover,
.activity-title .book-now-btn:active,
.activity-title .book-now-btn:focus {
  border: 1px solid #00aff4;
  background-color: #00aff4;
  color: #fff;
  outline: 0 none;
  box-shadow: unset;
}
.activity-content p {
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 3px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.activity-content p img,
.owl-carousel .owl-item .activity-content p img {
  margin-inline-end: 7px;
  height: 14px;
  width: 14px;
  display: inline-block;
}
/* .activity-content p.location {
  min-height: 18px;
} */
.activity-content p .fa {
  min-width: 20px;
  font-size: 14px;
}
.activity-content .cashback {
  color: #3fb46e;
}
.offer-price {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}
.activity-content p.actual-price {
  text-transform: uppercase;
}
.footer-pricing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  min-height: 68px;
}
.price-action {
  border-radius: 24px;
  border: 2px solid #00aff4;
  background: #fff;
  color: #00aff4;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 24px;
  transition: all 0.4s;
  font-weight: 600;
}
.price-action a {
  color: inherit;
}
.activity-card:hover .price-action,
.price-action .price-action:hover,
.price-action .price-action:active,
.price-action .price-action:focus {
  border: 2px solid #00aff4;
  color: #fff;
  background-color: #00aff4;
  box-shadow: 0 5px 10px rgb(0 0 0/20%);
}
.activity-card:hover .activity-media a img {
  transform: scale(1.2);
}
.trending-gradient {
  background-image: url("https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/Maskgroup22.png");
  background-repeat: no-repeat;
  background-size: 100% 240px;
  background-position: top left;
  padding: 30px 10px 0px 10px;
}
.recommended-gradient {
  background-image: url("../../assets/images/recommended-banner.png");
  background-repeat: no-repeat;
  background-size: 100% 240px;
  background-position: top left;
  padding: 30px 10px 0px 10px;
}
.collection-gradient {
  background-image: url("../../assets/images/collection-bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 240px;
  background-position: top left;
  padding: 30px 10px 0px 10px;
}
.trending-section {
  margin-top: 70px;
}
.trending-carousel .owl-stage-outer {
  padding-bottom: 40px;
}
.trending-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev {
  left: -110px;
}
.trending-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
  right: -110px;
}
.title-section a {
  color: #00aff4;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
}
.common-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 56px;
  margin: 0;
}
.title-wrap p {
  color: #000;
  line-height: 20px;
}
.recommended-gradient .common-title,
.collection-gradient .common-title,
.trending-gradient .common-title {
  color: #fff;
  padding-inline-start: 15px;
  padding-bottom: 25px;
}
.collection-section {
  margin-top: 60px;
}
.collection-carousel {
  padding-inline-start: 12px;
}
.collection-card {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  stroke: #e9e9e9;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
}
.collection-section .owl-item {
  padding-top: 10px;
}
.collection-card .collection-media img {
  max-width: 84px;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: cover;
}
.collection-section .collection-card,
.collection-media img,
.collection-title,
.collection-card a {
  transition: all 0.4s;
}
.collection-section .collection-card:hover,
.collection-section .collection-card:hover .collection-title,
.collection-section .collection-card:hover a {
  transform: translateY(-5px);
}
.collection-title {
  color: #985da6;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  min-height: 48px;
  max-width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 15px auto;
}
.sitemap-section .sitemap-links {
  font-size: 17px;
  line-height: 28px;
  color: #00aeef;
}
.collection-title ~ a {
  border-radius: 50%;
  background: rgba(143, 88, 156, 0.16);
  box-shadow: 0px 12px 24px 0px rgba(0, 175, 244, 0.2);
  height: 18px;
  width: 18px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  color: #985da6;
  margin: 0 auto;
}
.blogs-section {
  margin-top: 60px;
}
.owl-collection {
  max-width: 87%;
}
.owl-collection .owl-stage-outer {
  padding-bottom: 40px;
}
.rating-section .user-avtar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  min-width: 80px;
}
.trusted-companies li {
  max-width: 20%;
}
.download-media img {
  width: auto;
  max-width: 100%;
}
.feed-media img {
  max-width: 100%;
}
.login-modal,
.email-modal {
  background: rgba(0, 0, 0, 0.7);
}
.login-modal .modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-modal .modal-content {
  border-radius: 24px;
  border: 2px solid #fff;
  background: url("../images/loginbg.webp"),
    linear-gradient(239deg, #56bee7 0%, #0096d1 100%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  max-width: 90%;
  margin: 0 auto;
}
.email-modal .modal-content {
  border-radius: 24px;
  border: 2px solid #fff;
  background: url("../images/login-bg-colored.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top right;
  margin: 0 auto;
}
.login-modal .login-content {
  padding: 30px 0 15px;
}
.login-modal .modal-dialog,
.email-modal .modal-dialog {
  max-width: 585px;
}
.login-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 25px;
  color: #fff;
}
.login-text img {
  max-width: 92px;
}
.login-button {
  width: 80%;
  max-width: 360px;
}
.login-button .btn-primary {
  width: 100%;
  height: 48px;
  border: 2px solid #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
  padding: 0 40px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000;
  text-decoration: none;
  justify-content: center;
}
.login-button-group .btn-primary img {
  left: 5px;
  top: 6px;
  width: 32px;
  height: 32px;
  position: absolute;
}

.login-button-group .login-button:first-child .btn-primary {
  background-color: #f4d5c7;
}
.login-button-group .login-button:nth-child(2) .btn-primary {
  background-color: #c9daf2;
}
.login-button-group span {
  display: block;
  width: 100%;
  max-width: 360px;
}
.login-button-group .kep-login-facebook {
  background-color: #c9daf2;
  width: 100%;
  height: 48px;
  border: 2px solid #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px !important;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
  padding: 0 40px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000;
  text-decoration: none;
  justify-content: center;
  background-image: url("../images/fb.svg");
  background-repeat: no-repeat;
  background-position: 10px 5px;
  background-size: 32px;
  text-transform: unset;
  font-family: var(--bs-btn-font-family);
}
.login-button-group .login-button:nth-child(3) .btn-primary {
  background-color: #de4a39;
}
.login-button-group .login-button:last-child .btn-primary {
  background-color: #c9daf2;
}
.terms-text,
.terms-text a {
  font-size: 9px;
  line-height: 16px;
  padding-top: 12px;
  color: #fff;
}
.terms-text {
  margin: 0px auto 0px auto;
  text-align: center;
}
.terms-text a {
  text-decoration-line: underline;
}
.main-header .navbar .btn-primary {
  padding: 0.65rem 1.13rem;
  text-transform: capitalize;
  border-radius: 30px;
  border: 2px solid #fff;
  background-color: #f08146;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  transition: all 0.4s;
}
.main-header .navbar .btn-primary:hover,
.main-header .navbar .btn-primary:active,
.main-header .navbar .btn-primary:focus {
  border: 2px solid #c45c25;
  background-color: #c45c25;
}
.email-title {
  color: #201c1d;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  /* max-width: 340px;
  margin-left: 150px; */
  margin-bottom: 50px;
}
.email-modal .modal-body {
  padding: 45px 1rem 30px;
}
.email-title-section .kids-image {
  position: absolute;
  max-width: 150px;
  height: 121px;
  left: 20px;
  top: 20px;
}
.email-modal .form-control {
  border-radius: 12px;
  border: 1px solid #ebebeb;
  background-color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
  padding: 15px 15px;
}
.email-content .form-control:hover,
.email-content .form-control:active,
.email-content .form-control:focus {
  outline: 0 none;
  box-shadow: unset;
}
.email-content .form-control:not(.is-invalid) {
  background: url("../images/valid-icon.png");
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 18px;
  height: 56px;
  margin: 38px auto;
  border: 1px solid #ebebeb;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 16px 40px 16px 23px;
  position: relative;
  max-width: 480px;
}
.phone-code-wrap {
  display: flex;
  align-items: center;
}
.email-modal .guest-content .phone-code-wrap .form-control {
  border-radius: 0 12px 12px 0;
  width: calc(100% - 120px);
}
.email-modal .guest-content .react-tel-input .form-control {
  border-radius: 12px 0px 0px 12px;
  max-width: 120px;
  width: 100%;
  height: auto;
}
.phone-code-wrap .react-tel-input {
  max-width: 120px;
}
.email-modal .btn-primary {
  max-width: 320px;
  padding: 10px 16px;
  border-radius: 100px;
  font-weight: 700;
  margin: 0 auto 20px;
  display: block;
  width: 70%;
  font-size: 13px;
  height: 56px;
  border-radius: 12px;
  border: 2px solid #fff;
  background-color: #f08146;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
}
.email-modal .btn-primary:hover,
.email-modal .btn-primary:active,
.email-modal .btn-primary:focus {
  border: 2px solid #fff;
  background-color: #f08146;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  outline: 0 none;
}
.resend-content-mail p a {
  color: #00aff4;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.resend-content-mail p {
  margin: 0 auto;
  text-align: center;
}
.resend-content-mail p {
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 300px;
}
.resend-content-mail p.resend-mail span {
  text-decoration: underline;
  margin-bottom: 40px;
  display: inline-block;
}
.email-modal .resend-content-mail .btn-primary {
  margin: 30px auto;
}
.spacing-top {
  margin-top: 125px;
}
.about-wrapper.spacing-top {
  margin-top: 87px;
}
.spacing-top.experience-detail-wrapper {
  margin-top: 87px;
  padding-top: 0;
  background-color: #f8f8f8;
}
.other-payment .spacing-top.experience-detail-wrapper {
  margin-top: 0 !important;
}
.confirm-booking-wrapper .buy-section .btn-primary {
  margin-top: 0 !important;
}
.experience-detail-title-wrapper {
  background-color: #fff;
  padding: 20px 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 25px;
}
.experience-detail-title-wrapper .details-title {
  margin-top: 0;
}
.get-in-touch-form .save-btn-wrap .btn-primary {
  min-width: 150px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.get-in-touch-form {
  max-width: 900px;
  margin-inline: auto;
}
.how-works-video {
  text-align: center;
  padding-top: 15px;
}
.how-app-work .wrapper {
  display: inline-block;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
}
.how-app-work {
  padding: 60px 0;
  padding-bottom: 20px;
}
.how-app-work .wrapper .wrap {
  padding: 100px 60px;
  text-align: left;
}
.how-app-work .wrapper .wrap h1 {
  font-size: 42px;
  line-height: 48px;
  color: #484848;
}
.how-app-work .wrapper .wrap p {
  color: #666;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 10px;
}
.how-app-work .wrapper:last-child {
  border-bottom: none;
}
.how-works-video video.video {
  width: 100%;
  height: auto;
}
.filter-activities .btn-filter {
  padding: 7px 12px;
  border: 1px solid #8e9295;
  max-height: 40px;
  color: #999;
  margin-inline-end: 16px;
  margin-bottom: 40px;
}
.filter-activities .btn-filter:last-child {
  margin-inline-end: 0;
}
.filter-activities .btn-filter img {
  margin-inline-end: 8px;
}
.filter-activities .btn-filter.active-filter {
  border-color: #008ec7;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  color: #000;
  font-weight: 500;
}
.find-activities-wrapper.activities-listing-page .activity-card {
  transform: unset;
  overflow: unset;
}
.activities-listing-page .activity-card:hover .activity-media a img {
  transform: unset;
}
.activities-listing-page .activity-media {
  overflow: unset;
}
.activities-listing-page .price-text {
  margin: 0;
}
.activities-listing-page .deal-flag {
  border-radius: 0 8px 8px 0px;
  top: 15px;
  left: -5px;
}
.activities-listing-page
  .activity-cards-wrap
  .activity-card
  .activity-media
  img {
  border-radius: 12px;
}
.activities-listing-page .deal-flag::after {
  height: 0;
  width: 0;
  content: "";
  left: 1px;
  position: absolute;
  top: -11px;
  border: 6px solid transparent;
  border-bottom-color: #ad6903;
  border-right-color: #ad6903;
}
.activities-listing-page .featured-section .deal-flag::after {
  z-index: -1;
}
.activities-listing-page .deal-flag {
  border-radius: 0 8px 8px 0px;
  top: 15px;
  left: -7px;
}
.activity-subtitle {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  opacity: 0.7;
}
/* My Account CSS Start */
.account-tab-content {
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 30px 24px 30px 40px;
}
.account-sidebar {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin-bottom: 30px;
}
.user-info {
  position: relative;
  background-image: linear-gradient(234deg, #56bee7 0%, #0096d1 100%);
  padding-top: 100px;
}
.wrap-info {
  position: absolute;
  top: calc(50% + 65px);
  transform: translateY(-50%);
  width: 100%;
}
.update-profile-image input#profilePhotoFile {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
.update-profile-image {
  height: 124px;
  width: 124px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.update-profile-image img {
  width: 100%;
  height: 100%;
}
.about-wrapper {
  padding-top: 20px;
}
.go-back {
  display: inline-block !important;
}
.go-back span {
  color: #000;
  margin-inline-start: 7px;
}
.user-name {
  color: #201c1d;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 8px 0 0 0;
}
.account-sidebar .nav-wrap {
  margin-top: 115px;
}
.wallet-link {
  background-image: url("../images/wallet-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
}
.wallet-text p {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
}
.experience-carousel .owl-stage-outer {
  margin-bottom: 20px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccc;
}
.experience-carousel .owl-stage-outer {
  height: 35px;
}
.wallet-text p strong {
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  display: inline-block;
}
.stacked-pills {
  margin-top: 15px;
}
.arrow-left-auto {
  margin-inline-start: auto;
}
.wallet-link .arrow-left-auto {
  color: #fff;
}
.pill-label strong {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
.pill-label span {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.5;
}
.nav-pills .arrow-left-auto {
  color: #000;
}
.account-sidebar .nav-pills .nav-link.active {
  background-color: #fff;
}
.account-sidebar .nav-pills .nav-item .nav-link {
  border-radius: 0;
  padding: 10px 0;
  padding-right: 16px;
}
.account-sidebar .nav-pills .nav-item + .nav-item .nav-link {
  border-top: 1px solid #ccc;
}
.pill-icon {
  margin-inline-end: 12px;
}
.wallet-icon {
  margin-inline-end: 12px;
}
.account-edit .form-control {
  border-radius: 12px;
  background: #ecf4f8;
  color: #000;
  padding: 10px 15px;
}
.form-group .form-control:hover,
.form-group .form-control:active,
.form-group .form-control:focus {
  outline: 0 none;
  box-shadow: unset;
}
.account-edit .form-label {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.country-group {
  position: relative;
}
.country-group .contry-code-select:hover,
.country-group .contry-code-select:active,
.country-group .contry-code-select:focus {
  outline: 0 none;
  box-shadow: unset;
}
.account-edit {
  margin-top: 30px;
}
.country-group .react-tel-input .form-control {
  width: 100%;
  border-radius: 12px;
  background: #ecf4f8;
  color: #000;
  padding: 10px 15px;
  padding-left: 56px;
}
.selected-flag .flag.eg .arrow {
  display: none;
}
.react-tel-input .selected-flag {
  border-right: 1px solid #cacaca;
}
.account-edit .country-group.phone-group-wrap .react-tel-input .form-control {
  height: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: medium none;
  border-color: #dee2e6;
  max-height: 45px;
  padding-left: 45px;
  max-width: 120px;
  padding-right: 0;
}
.country-group .react-tel-input .selected-flag {
  width: 40px;
}
.phone-group-wrap .react-tel-input + .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: medium none;
  width: 100%;
  padding-left: 10px;
}
.account-edit .form-control:hover,
.account-edit .form-control:active,
.account-edit .form-control:focus {
  outline: 0 none;
  box-shadow: unset;
}
.country-group .react-tel-input .country-list .search-box {
  border: 1px solid #efefef;
  background: #ecf4f8;
  width: 95%;
}
.country-group .react-tel-input .selected-flag:hover,
.country-group .react-tel-input .selected-flag:active,
.country-group .react-tel-input .selected-flag:focus,
.country-group .react-tel-input .form-control:hover,
.country-group .react-tel-input .form-control:active,
.country-group .react-tel-input .form-control:focus {
  outline: 0 none !important;
  box-shadow: unset !important;
}
.phone-group-wrap.country-group {
  display: flex;
}
.phone-group-wrap.country-group .special-label {
  display: none;
}
.phone-group-wrap.country-group .react-tel-input {
  max-width: 120px;
}
.venue-info-wrap {
  background-image: url("../images/venue-bg.png");
  background-repeat: no-repeat;
  padding: 30px 26px;
  background-size: 100% 272px;
  margin: 20px -10px;
}
.qr-code-wrap {
  background-color: #fff;
  border: 1px solid #efefef;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 16px;
}
.qr-image-wrap {
  margin: 0 auto;
  max-width: 120px;
  background-color: #f8f8f8;
  border-radius: 8px;
}
.qr-image-wrap img {
  width: 120px;
  height: 120px;
}
.add-cal span {
  background-color: #e45c20;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.add-cal button {
  all: unset;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
}
.add-cal button:is(:hover, :active, :focus) {
  background: #fff;
}
.add-cal {
  background-color: #fff;
  border: 1px solid #efefef;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 30px;
  overflow: hidden;
}
.my-booking-title-wrap .my-booking-title {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}
.my-booking-title-wrap p {
  color: #201c1d;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
#addCalender .modal-dialog {
  max-width: 600px;
}
#addCalender .modal-dialog .modal-body {
  padding: 20px 30px;
}
.my-booking-title-wrap {
  position: relative;
  min-height: 140px;
  padding-top: 20px;
  margin-bottom: 30px;
}
.my-booking-title-wrap img {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 140px;
}
.add-cal p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin: 0;
}
.qr-code-wrap p {
  margin: 0;
}
.qr-code-wrap .qr-content p {
  padding: 16px;
  margin: 0;
  color: #201c1d;
  font-size: 14px;
  line-height: 20px;
}
.qr-footer p {
  color: #27ae60;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 12px;
}
.qr-footer {
  background-color: #ecf8f1;
  margin-top: 30px;
}
.qr-code-wrap p span,
.qr-code-wrap p strong {
  display: block;
}
.venue-content-wrap {
  display: flex;
}
.venue-content-wrap .venue-media {
  max-width: 200px;
  border-radius: 12px;
  overflow: hidden;
}
.venue-content-wrap .venue-media img {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  height: 140px;
}
.venue-content {
  padding-left: 25px;
}
.venue-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.venue-content .fun-list + .fun-list {
  margin-top: 20px;
}
.venue-time li {
  color: #27ae60;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.venue-time {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.venue-footer .fun-list:last-child li {
  margin-bottom: 0;
  margin-top: 10px;
}
.venue-footer {
  padding: 0 15px;
}
.account-title {
  color: #201c1d;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
.save-btn-wrap .btn-primary {
  border-radius: 12px;
  border: 1px solid #fff;
  background: #00aff4;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 30px;
}
.custom-checkbox .form-check {
  position: relative;
  padding-left: 0;
}
.logout-btn-wrap .btn-primary {
  border-radius: 24px;
  background: #ecf4f8;
  border: 2px solid #ecf4f8;
  color: #00aff4;
  text-align: right;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 11px 47px;
}
.logout-btn-wrap {
  text-align: center;
  margin: 40px 0;
}
.profile-edit.account-edit .save-btn-wrap {
  max-width: 177px;
  margin-top: 30px;
}
.save-btn-wrap.map-save-btn {
  float: right;
  margin: 28px 0;
}
.phone-group-wrap.country-group .flag-dropdown,
.country-group .flag-dropdown .selected-flag {
  background: transparent;
  border: none;
}

.custom-checkbox .form-check .form-check-label {
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #efefef;
  padding: 8px 25px 8px 12px;
  cursor: pointer;
}
.custom-checkbox .form-check .form-check-input {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
  border: 1px solid #efefef;
  cursor: pointer;
  top: 5px;
  z-index: 1;
}
.account-edit .custom-checkbox {
  margin-bottom: 24px;
}
.custom-checkbox .form-check-input:checked {
  opacity: 0;
}
.custom-checkbox .form-check-input:hover,
.custom-checkbox .form-check-input:active,
.custom-checkbox .form-check-input:focus {
  outline: 0 none;
  box-shadow: unset;
}
.custom-checkbox .form-check-input:checked + .form-check-label {
  background-color: #00aff4;
  background-image: url("../images/custom-checked.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 95% 50%;
  border-radius: 12px;
  border: 2px solid #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  color: #fff;
}
.location-edit .save-btn-wrap .btn-primary {
  padding: 6px 16px;
}
.location-edit .form-group {
  position: relative;
}
.location-edit .save-btn-wrap {
  right: 4px;
  top: 4px;
  position: absolute;
}
.about-content .about-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #000;
  margin: 0;
}
.about-navbar.nav-pills .nav-link span {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
.bg-border-gray {
  background: #f8f8f8;
  height: 10px;
  margin: 12px 0;
}
.about-banner img {
  max-width: 100%;
}
.page-title {
  font-size: 52px;
  color: #484848;
  line-height: 57px;
  margin-bottom: 30px;
  font-weight: 600;
}
.our-story-wrapper .about-banner {
  margin-bottom: 60px;
}
.about-banner img {
  max-width: 600px;
  width: 100%;
  margin-bottom: 40px;
}
.page-section-title {
  color: #484848;
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.about-media img {
  max-width: 386px;
  width: 100%;
  margin: 20px auto 40px;
}
.about-content-wrap {
  margin-top: 60px;
}
.kids-feed {
  margin-bottom: 70px;
  margin-top: 60px;
  border-radius: 12px;
  overflow: hidden;
}
.kids-feed-content {
  width: 70%;
  background-color: #00aff4;
  padding: 60px 80px;
}
.kids-feed-media {
  width: 30%;
}
.kids-feed-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kid-feed-title {
  color: #fff;
  line-height: 60px;
  font-size: 50px;
  text-align: center;
  margin-bottom: 0;
  font-weight: 600;
}
.kids-feed-content p {
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.kids-feed-form .form-control {
  width: 70%;
  border-radius: 25px 0 0 25px;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  padding: 10px 25px;
  color: #8d969b;
}
.kids-feed-form .btn-primary {
  width: 30%;
  border-radius: 0 25px 25px 0;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  line-height: 21px;
  height: 40px;
  color: #fff;
  background-color: #e25b25;
  cursor: pointer;
  text-transform: capitalize;
}
.kids-feed-form .btn-primary:hover,
.kids-feed-form .btn-primary:active,
.kids-feed-form .btn-primary:focus {
  color: #fff;
  background-color: #e25b25;
  outline: 0 none;
  box-shadow: unset;
}
.kids-feed-value {
  width: 30%;
  border-right: 1px solid #fff;
  padding-right: 25px;
  padding-left: 25px;
}
.kids-feed-value:first-child {
  padding-left: 0;
}
.kids-feed-value:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.kids-feed-value:nth-child(2) {
  width: 40%;
}
.kids-feed-value .feed-values {
  font-size: 36px;
  line-height: 30px;
  color: #fff;
  text-align: left;
  font-weight: 600;
}
.kids-feed-content .kids-feed-value p {
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin: 0;
}
.kids-feed-form {
  margin: 30px auto;
}
.get-in-touch-banner {
  background-image: url("https://s3.me-south-1.amazonaws.com/testimagesbah.kidzapp.com/profilepics/1699258494804get-touch-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px 0;
}
.page-banner-title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 10px;
}
.get-in-touch-banner p {
  font-size: 18px;
  max-width: 520px;
  margin: 0 auto;
  line-height: 24px;
  font-weight: 500;
}
/* My Account CSS End */
/* Booking detail page CSS Start */
.booking-page-content {
  margin-bottom: 30px;
}
.primary-blue-title {
  color: #008ec7;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}
.booking-page-content .meta {
  margin-top: 20px;
}
.booking-page-content .inner-meta img {
  margin-inline-end: 8px;
}
.booking-page-content .inner-meta label {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000;
  opacity: 0.6;
}
.booking-page-content .inner-meta {
  margin-bottom: 15px;
}
.payment-method .card {
  padding: 15px;
  background: #fff;
  border: 0.965517px solid #efefef;
  box-shadow: 0 11.5862px 19.3103px rgba(0, 0, 0, 0.06);
  border-radius: 11.5862px;
  margin-bottom: 43px;
  margin-top: 20px;
  width: 100%;
}
.payment-method h6 {
  font-size: 15px;
  line-height: 21px;
  color: #000;
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 0;
}
.payment-method h4 {
  font-weight: 400;
  font-size: 15.9237px;
  line-height: 32px;
  color: #201c1d;
  margin-bottom: 0;
}
.hover-img img {
  transform: scale(1);
  transition: 0.9s;
  max-width: 100%;
  width: 100%;
  visibility: visible;
  height: 100%;
  border-radius: 12px;
}
.detail-hover-wrap .hover-img img {
  border-radius: 0px;
}
.hover-img:hover img {
  transform: scale(1.1);
}
.image-grid {
  padding: var(--gap);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-auto-rows: 200px; */
  gap: 16px;
  position: relative;
}
.image-grid > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-grid-col-2:first-child {
  grid-column: span 2;
  grid-row: span 2;
}
.detail-hover-wrap .hover-img {
  border-radius: 0px;
  overflow: hidden;
  max-height: 220px;
}
.experience-detail-wrapper .hover-img {
  position: relative;
}
.lightbox-btn.btn-primary {
  border-radius: 6px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #008ec7;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 6px 14px;
}
.lightbox-rating-btn {
  border-radius: 6px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #201c1d;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header-book-now-btn a {
  border-radius: 12px;
  border: 1px solid transparent;
  background: #f08146;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding: 12px 24px;
}
.details-image-buttons {
  position: absolute;
  bottom: 11px;
  right: 11px;
  width: 100%;
}
.experience-detail-wrapper .detail-hover-wrap {
  margin-bottom: 0;
  margin-inline: 0;
}
.experience-detail-wrapper .detail-hover-wrap .each-detail-media:first-child {
  padding-left: 0;
}
.experience-detail-wrapper .detail-hover-wrap .each-detail-media:last-child {
  padding-right: 0;
}
.each-detail-media:first-child .lightbox-btn.btn-primary {
  display: none;
}
.each-detail-media:last-child .lightbox-btn.btn-primary {
  display: inline-block;
}
.detail-hover-wrap {
  margin-bottom: 40px;
}
.each-detail-submedia .hover-img {
  max-height: unset !important;
}
.each-detail-submedia:first-child .hover-img,
.each-detail-submedia:nth-child(2) .hover-img {
  margin-bottom: 15px;
}
.grid-view-images .hover-img {
  overflow: hidden;
}
.grid-view-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  grid-auto-rows: 210px;
  overflow: hidden;
  padding: 0;
}
.grid-image1 {
  grid-column: span 2;
}
.grid-image2 {
  grid-column: span 1;
  overflow: hidden;
}
.grid-image3 {
  grid-column: 3;
  grid-row: 2;
  overflow: hidden;
}
.fun-sec .booking-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 24px;
  color: #000;
  margin-bottom: 18px;
}
.fun-list li {
  padding-inline-end: 24px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}
.fun-list li img {
  height: 20px;
  width: 20px;
  margin-inline-end: 5px;
}
.new-card-row .form-label {
  display: block;
  height: 10px;
  color: #13395e;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}
.payment-cards .icon-group img {
  left: 2px;
  right: unset;
  top: 30px;
  width: auto;
  max-width: 20px;
}
.payment-cards .icon-group .form-label {
  display: block;
  height: 10px;
  color: #13395e;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 20px 0 8px;
}
.other-payment .other-pay-title {
  font-weight: 600;
  margin-bottom: 20px;
}
.other-payment .google-pay-button-container {
  display: block;
  text-align: center;
}
.booking-sidebar.payment-sidebr {
  background-color: #f9f9f9;
  padding: 20px 30px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
.payment-cards .icon-group .form-control {
  border-color: transparent transparent #bcb9b9 transparent;
  border-radius: 0;
  background-color: #fff;
  padding-left: 35px;
}
.payment-cards .saved-card-group {
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #fff;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 15px;
  position: relative;
  margin-top: 20px;
}
.new-card-row {
  margin-top: 30px;
}
.payment-cards .saved-card-group span {
  margin-inline-start: 8px;
}
.payment-cards .saved-card-group input[type="radio"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.payment-cards input[type="radio"] ~ .form-check-label .payment-selected-icon {
  display: none;
  position: absolute;
  right: 10px;
  width: 14px;
  top: 20px;
}
.payment-cards
  input[type="radio"]:checked
  ~ .form-check-label
  .payment-selected-icon {
  display: inline-block;
}
.payment-cards .name-group.icon-group .form-control {
  padding-left: 5px;
}
.payment-cards .icon-group .form-control.is-invalid {
  border-color: transparent transparent #dc3545 transparent;
}
.booking-sidebar .form-label {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.booking-wrapper .form-select {
  border-radius: 12px;
  border: 1px solid #ebebeb;
  background: #f9f9f9 !important;
  margin-top: 20px;
}
.booking-wrapper .form-select:hover,
.booking-wrapper .form-select:active,
.booking-wrapper .form-select:focus {
  box-shadow: unset;
  outline: 0 none;
}
.booking-sidebar .react-datepicker-wrapper {
  width: 100%;
}
.booking-sidebar .react-datepicker-wrapper input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.booking-sidebar .form-control,
.booking-sidebar .react-datepicker-wrapper input[type="text"] {
  border-radius: 12px;
  border: 1px solid #ebebeb;
  background-color: #f9f9f9;
}
.booking-sidebar .form-control:hover,
.booking-sidebar .react-datepicker-wrapper input[type="text"]:hover,
.booking-sidebar .form-control:active,
.booking-sidebar .react-datepicker-wrapper input[type="text"]:active,
.booking-sidebar .form-control:focus,
.booking-sidebar .react-datepicker-wrapper input[type="text"]:focus {
  outline: 0 none;
  box-shadow: unset;
}
.group-icon {
  position: relative;
}
.group-icon .icon-right {
  position: absolute;
  right: 10px;
  top: 40px;
  max-width: 18px;
  height: auto;
  z-index: 1;
}
.booking-wrapper {
  border-radius: 12px;
  border: 1px solid#EFEFEF;
  background: #fff;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  margin-top: 12px;
  padding: 24px 28px;
}
.confirm-user-avtar img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  object-fit: cover;
}
.payment-title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.quantity-wrapper {
  border-radius: 12px;
  border: 1px solid #efefef;
  background: #fff;
  padding: 16px;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
}
.quantity-title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  opacity: 0.7;
}
.quantity-wrapper .user-contact-detail li {
  max-width: 50%;
  width: 100%;
}
.event-label {
  color: #201c1d;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.package-includes p {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  opacity: 0.7;
}
.package-includes span {
  color: #201c1d;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.packge-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quantity-info {
  position: relative;
  max-width: 130px;
}
.packge-info .package-includes {
  width: calc(100% - 140px);
}
.booking-sidebar .quantity-info .form-control {
  border-radius: 6px;
  border: 1px solid #c9c9c9;
  background-color: #fff;
}
.quantity-info .btn-primary {
  color: #00aff4;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  background-color: #fff;
  border: medium none;
  position: absolute;
  top: 5px;
  z-index: 1;
}
.quantity-info .increase-quantity {
  left: 2px;
}
.quantity-info .decrease-quantity {
  right: 2px;
}
.booking-sidebar .quantity-info .form-control {
  border-radius: 6px;
  border: 1px solid #c9c9c9;
  background-color: #fff;
  position: relative;
  text-align: center;
}
.cashback-info p {
  margin: 0;
}
.cashback-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
}
.cashback-info .text-success {
  font-size: 12px;
  line-height: 18px;
}
.total-package-price {
  color: #e45c20;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.event-booking {
  padding: 16px 0;
}
.event-booking + .event-booking {
  border-top: 1px solid #e5e5e5;
}
.subtotal-wrapper {
  background-color: #fff;
  border: 1px solid #efefef;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.06);
  margin-top: 16px;
  border-radius: 12px;
  padding-top: 12px;
}
.subtotal-wrapper p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px 16px;
  margin-bottom: 10px;
}
.total-amount {
  background-color: #ecf4f8;
  border: 1px solid #00aff4;
  border-radius: 16px;
  padding: 8px 0;
}
.total-amount p {
  margin: 0;
}
.special-request {
  margin-top: 20px;
}
.booking-sidebar .special-request .form-control {
  border-radius: 12px;
  background-color: #ecf4f8;
  color: #15192c;
  font-size: 14px;
  line-height: 24px;
  resize: none;
}
.common-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 56px;
  margin: 0;
}
.title-section a {
  color: #00aff4;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
}
.recommended-gradient .common-title,
.collection-gradient .common-title,
.trending-gradient .common-title {
  color: #fff;
  padding-inline-start: 15px;
  padding-bottom: 25px;
}
.trusted-companies li {
  max-width: 20%;
}
.activity-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  transform: translateY(5px);
  margin: 0 15px;
}
.activity-card:hover {
  transform: translateY(-5px);
}
.activity-card:hover .price-action,
.price-action .price-action:hover,
.price-action .price-action:active,
.price-action .price-action:focus {
  border: 2px solid #00aff4;
  color: #fff;
  background-color: #00aff4;
  box-shadow: 0 5px 10px rgb(0 0 0/20%);
}
.activity-card:hover .activity-media a img {
  transform: scale(1.2);
}
.activity-media {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  max-height: 156px;
}
.activity-media a img {
  transition: all 0.6s;
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: cover;
}
.deal-flag img {
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: cover;
}

.deal-flag {
  display: flex;
  background-image: linear-gradient(to right, #e49827, #e45b27);
  position: absolute;
  left: 0;
  padding: 6px 8px;
  border-radius: 8px 0;
}
.deal-flag span {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: 3px;
}
.activity-content {
  padding: 12px 16px;
  /* min-height: 155px; */
}
.activity-content p {
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 3px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.activity-content p img,
.owl-carousel .owl-item .activity-content p img {
  margin-inline-end: 7px;
  height: 14px;
  width: 14px;
  display: inline-block;
}
/* .activity-content p.location {
      min-height: 18px;
    } */
.activity-content p .fa {
  min-width: 20px;
  font-size: 14px;
}
.activity-content .cashback {
  color: #3fb46e;
}
.activity-content p.actual-price {
  text-transform: uppercase;
}
.activity-title.d-flex.justify-content-between a {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.activity-title {
  color: #201c1d;
  font-size: 16px;
  font-weight: 600;
  min-height: 20px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 15px 0 0 0;
}
.activity-title a {
  color: #201c1d;
  margin: 0;
}
.activity-title .book-now-btn {
  border-radius: 24px;
  border: 1px solid #00aff4;
  background: #fff;
  padding: 8px 20px;
  display: inline-block;
  color: #00aff4;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.activity-title .book-now-btn:hover,
.activity-title .book-now-btn:active,
.activity-title .book-now-btn:focus {
  border: 1px solid #00aff4;
  background-color: #00aff4;
  color: #fff;
  outline: 0 none;
  box-shadow: unset;
}
.footer-pricing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 5px 0;
  min-height: 68px;
}
.offer-price {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}
.price-action {
  border-radius: 24px;
  border: 2px solid #00aff4;
  background: #fff;
  color: #00aff4;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 24px;
  transition: all 0.4s;
  font-weight: 600;
}
.price-action a {
  color: inherit;
}
.blog-card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.blog-post-flag {
  background-color: #fcc734;
  padding: 6px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  left: 0px;
  top: 24px;
}
.blog-content {
  position: absolute;
  bottom: 20px;
  left: 0;
}
.blog-post-title {
  font-size: 24px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
.blog-col:first-child .blog-post-title {
  font-size: 32px;
  line-height: 40px;
}
.blog-col .blog-card:last-child {
  margin-top: 30px;
}
.blog-post-text {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
.blogs-mobile-carousel .owl-stage {
  padding-left: 0 !important;
}
.blogs-mobile-carousel {
  margin-top: 30px;
}
.blogs-section {
  margin-top: 60px;
}
.blog-media {
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  position: relative;
}
.blog-media a {
  display: block;
}
.collection-section {
  margin-top: 60px;
}
.collection-section .owl-item {
  padding-top: 10px;
}
.collection-card .collection-media img {
  max-width: 84px;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: cover;
}
.collection-section .collection-card,
.collection-media img,
.collection-title,
.collection-card a {
  transition: all 0.4s;
}
.collection-section .collection-card:hover,
.collection-section .collection-card:hover .collection-title,
.collection-section .collection-card:hover a {
  transform: translateY(-5px);
}
.collection-gradient {
  background-image: url("../images/collection-bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 240px;
  background-position: top left;
  padding: 30px 10px 0px 10px;
}
.collection-carousel {
  padding-inline-start: 12px;
}
.testimonial-section {
  margin-top: 110px;
  overflow: hidden;
  margin-bottom: 50px;
}
.item-content-wrap {
  border-radius: 12px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  padding-inline-start: 40px;
}
.testimonial-content .school-name {
  color: #00aff4;
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  margin: 25px 0 0;
}
.programme-name {
  color: #000;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
}
.testimonial-content .testimonial-text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 45px;
  max-width: 540px;
  min-height: 72px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.user-avtar {
  border-radius: 50%;
  border-left: 2px solid #00aff4;
  padding: 12px;
}
.user-details .user-name {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.testimonial-added {
  color: #000;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  opacity: 0.5;
  margin: 0;
}
.user-details .rating-stars .fa-star {
  color: #ccc;
}
.user-details .rating-stars .fa-star + .fa-star {
  margin-inline-start: 3px;
}
.user-details .rating-stars .fa-star.checked {
  color: #fdd42d;
}
.testimonial-section .common-title {
  max-width: 700px;
  margin-bottom: 50px;
  font-size: 48px;
}
.rating-number {
  color: #201c1d;
  font-size: 14px;
  line-height: 20px;
  margin-inline-start: 8px;
}
.user-details {
  padding-inline-start: 20px;
}
.testimonial-media {
  border-radius: 12px;
  border: 4px solid #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin-top: -30px;
  margin-right: -30px;
  margin-bottom: 50px;
  max-height: 350px;
}
.testimonial-carousel .owl-carousel .owl-stage-outer {
  padding-block: 40px;
}
.item-content-wrap {
  border-radius: 12px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  padding-inline: 40px;
  max-width: calc(100% - 40px);
  margin-inline: auto;
  background: linear-gradient(
    to right,
    rgba(0, 175, 244, 0.12) 0%,
    rgba(32, 124, 202, 0) 30%,
    rgba(0, 175, 224, 0) 70%,
    rgba(0, 175, 244, 0.12) 100%
  );
}
.testimonial-carousel .slick-slide.slick-center .item-content-wrap {
  background: white;
}
.testimonial-carousel .rating-stars svg {
  height: 0.9em;
}
.testimonial-carousel .center ~ .owl-item .item-content-wrap {
  background: linear-gradient(
    to right,
    rgba(0, 175, 244, 0.12) 0%,
    rgba(32, 124, 202, 0) 15%,
    rgba(0, 175, 224, 0) 80%,
    rgba(0, 175, 244, 0.12) 100%
  );
}
.testimonial-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev {
  left: -50px;
}
.testimonial-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
  right: 60px;
}
.testimonial-carousel .owl-carousel .owl-dots {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 100%;
  max-width: 1200px;
  text-align: right;
}
.testimonial-content {
  margin-bottom: 40px;
}
.testimonial-section {
  position: relative;
}
.testimonial-section .owl-carousel.owl-theme .owl-nav {
  top: -130px;
  width: 100px;
  right: 80px;
}
.rating-section .user-avtar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  min-width: 80px;
}
.user-name {
  color: #201c1d;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 8px 0 0 0;
}
/* slick slider css    */

.slick-slider .slick-arrow {
  border-radius: 48px;
  border: 2px solid #fff;
  background: #e3f2f8;
  box-shadow: 0px 12px 24px 0px rgba(0, 175, 244, 0.2);
  color: #000000;
  padding: 8px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
.slick-slider .slick-arrow:is(:hover, :active, :focus) {
  color: #000000;
  border: 2px solid #fff;
  background: #fff;
}
.slick-slider .slick-arrow.slick-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.show-all-btn {
  position: absolute;
  top: 11px;
  right: 82px;
  z-index: 1;
  width: 154px;
  display: flex !important;
  gap: 0.5rem;
  align-items: center;
}
.about-wrap-images img {
  border-radius: 12px;
}

@media screen and (max-width: 767.98px) {
  .testimonial-media {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    max-height: 400px;
    width: auto;
    max-width: 90%;
    margin-inline: auto;
  }
  .show-all-btn {
    right: 7px;
    top: 66px;
  }
  .details-image-buttons {
    right: 0;
    bottom: 18px;
  }
  .testimonial-content .testimonial-text {
    margin-bottom: 10px;
    max-width: 100%;
  }
  .testimonial-section .common-title {
    margin-bottom: 20px;
  }
  .testimonial-section {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .testimonial-section .owl-stage-outer .owl-stage {
    padding-bottom: 20px;
  }
  .testimonial-carousel .owl-carousel .owl-stage-outer {
    padding-block: 0px;
  }
  .testimonial-content .school-name {
    font-size: 18px;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .testimonial-content {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .testimonial-media {
    display: none;
  }
  /* .testimonial-carousel .owl-carousel .owl-nav.disabled{
    display: none;
} */
  .header-book-now-btn {
    margin-top: 16px;
  }
  .header-book-now-btn,
  .header-book-now-btn a {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
  .about-wrapper .slick-dots li.slick-active button::before {
    color: #00aff4;
    /* scale: 1.5; */
  }
  /* .about-wrapper .title-wrap {
    margin-top: 15px;
  } */
}
@media screen and (max-width: 575.98px) {
  .testimonial-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev {
    left: 10px;
  }
  .testimonial-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
    right: 10px;
  }
  .experience-detail-wrapper .detail-hover-wrap > div {
    padding-left: 0;
    padding-right: 0;
  }
  .experience-detail-title-wrapper,
  .experience-detail-wrapper .booking-page-content {
    border-radius: 0 !important;
  }
  .item-content-wrap {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    max-width: 100%;
  }
  .user-details {
    padding-inline-start: 5px;
  }
  .user-details .user-name {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .programme-name {
    font-size: 20px;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.owl-collection {
  max-width: 90%;
}
.owl-collection .owl-stage-outer {
  padding-bottom: 40px;
}
.collection-card {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  stroke: #e9e9e9;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
}
.collection-title ~ a {
  border-radius: 50%;
  background: rgba(143, 88, 156, 0.16);
  box-shadow: 0px 12px 24px 0px rgba(0, 175, 244, 0.2);
  height: 18px;
  width: 18px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  color: #985da6;
  margin: 0 auto;
}
.collection-title {
  color: #985da6;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  min-height: 48px;
  max-width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 15px auto;
}

@media screen and (max-width: 767.98px) {
  .blogs-mobile-carousel .blog-media img {
    min-height: 200px;
  }
  .blogs-mobile-carousel .owl-collection .owl-stage-outer {
    padding-bottom: 0px;
  }
}

.remember-section .form-group {
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
}
.remember-section .form-group .form-check-label {
  color: #000;
  font-size: 12px;
  margin-inline-start: 10px;
}
.remember-section .form-group .form-check-label a {
  color: #00aff4;
  font-weight: 500;
}
.remember-section .form-group .form-check-input {
  border: 1.5px solid #00aff4;
}
.remember-section .form-group .form-check-input:checked {
  background-color: #00aff4;
}
.remember-section .form-group .form-check-input:hover,
.remember-section .form-group .form-check-input:active,
.remember-section .form-group .form-check-input:focus {
  box-shadow: unset;
  outline: 0 none;
}
.buy-section .btn-primary {
  border-radius: 12px;
  border: 2px solid #fff;
  background: #f08146;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  color: #fff;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 10px;
  margin-top: 20px;
}
.buy-section .btn-primary:hover,
.buy-section .btn-primary:active,
.buy-section .btn-primary:focus {
  outline: 0 none;
  box-shadow: unset;
  border: 2px solid #fff;
  background: #f08146;
}
.booking-fees-section small {
  margin-inline-start: 5px;
  font-size: 12px;
}
.booking-fees-section p {
  margin: 0;
}
.booking-fees-section p:first-child {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 6px;
}
.booking-fees-section {
  background-color: #ecf4f8;
  border: 1px solid #00aff4;
  border-radius: 16px;
  padding: 16px;
  margin-top: 18px;
}
.total-paid-amount {
  background-color: #fcdddd;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 16px;
  margin-top: 20px;
}
.venue-amount {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
}
.venue-amount strong + strong {
  color: #f05355;
}
.spec strong {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.total-paid-amount .venue-amount {
  margin-bottom: 12px;
}
.spec p {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.spec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spec span {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.7;
}
.each-detail-media.single-media .hover-img {
  height: auto;
  max-height: 400px;
  border-radius: 12px;
}
.spec p + p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.review-title {
  color: #201c1d;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
  margin-bottom: 0;
}
.offer-deal {
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  margin: 30px 0 24px 0;
}
.offer-deal-icon {
  position: relative;
  width: 65.625px;
  height: 65.625px;
  margin-left: 10px;
}
.offer-deal-icon img {
  position: absolute;
  width: 78px;
  height: 78px;
  top: -18px;
}
.offer-deal-text p {
  margin: 0;
  color: #f05355;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 25px;
}
ul.what-offers {
  display: flex;
  flex-wrap: wrap;
}
.what-offers li {
  width: 100%;
  max-width: 50%;
  padding-left: 30px;
  margin-top: 16px;
  position: relative;
}
/* .guarantee-text-section {
    margin-bottom: 40px;
} */
.what-offers li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-image: url("../images/offer-blue-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
}
.experience-package {
  border-radius: 12px;
  border: 1px solid #efefef;
  background: #fff;
  overflow: hidden;
}
.package-header {
  background-color: #e3f2f8;
  padding: 10px 16px;
}
.package-header .package-title {
  color: #201c1d;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.package-header p {
  margin: 0;
  color: #848484;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.package-amount .cashback-offer {
  color: #3fb46e;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  text-align: end;
}
.experience-package + .experience-package {
  margin-top: 12px;
}
.package-amount .prices del {
  color: #848484;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.package-amount .prices span {
  color: #e45c20;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.package-amount p strike {
  color: #848484;
  font-size: 14px;
  line-height: 16px;
}
.package-amount p span {
  font-size: 14px;
  line-height: 16px;
  color: #201c1d;
  font-weight: 600;
  margin-inline-start: 5px;
}
.package-name p {
  opacity: 0.7;
  color: #848484;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
}
.package-info {
  display: flex;
  justify-content: space-between;
}
.package-info + .package-info {
  margin-top: 10px;
}
.package-content > div + div {
  margin-top: 10px;
}
.package-includes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 8px 0;
}
.package-includes .btn-secondary {
  padding: 0;
  background-color: transparent;
  border: medium none;
  color: #00aff4;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
}
.package-includes .btn-secondary:hover,
.package-includes .btn-secondary:active,
.package-includes .btn-secondary:focus {
  background-color: transparent;
  color: #00aff4;
  outline: 0 none;
  box-shadow: unset;
  border: medium none;
}
.package-includes .btn-primary {
  border-radius: 6px;
  border: 1px solid transparent;
  background: #f08146;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 12px;
}
.package-includes .btn-primary:hover,
.package-includes .btn-primary:active,
.package-includes .btn-primary:focus {
  outline: 0 none;
  box-shadow: unset;
  border: 1px solid transparent;
  background: #f08146;
}
.package-content {
  padding: 10px 16px;
}
.package-name h6 {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.7;
  margin: 0;
}
.review-text .read-more-text,
.read-more-text {
  color: #00aff4;
  font-size: 16px;
  line-height: 24px;
}
.review-text p {
  margin-right: 8px;
}
.review-text p,
.review-text {
  position: relative;
  transition: all 0.4s;
}
.terms-updated {
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 15px 0px;
}
.entry-text {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
}
.review-text ul {
  padding-inline-start: 15px;
}
.review-text ul li {
  list-style: disc;
  color: #000;
  font-size: 13px;
  line-height: 18px;
  margin-top: 12px;
}
.review-title .fa {
  position: absolute;
  right: 0;
  top: 26px;
  font-size: 16px;
  color: #201c1d;
  opacity: 0.8;
}
.details-title {
  color: #201c1d;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 12px;
  position: relative;
}
.sharing-option {
  justify-content: flex-end;
}
.sharing-option .link-item {
  position: relative;
}
.sharing-option .link-item:first-child {
  max-width: calc(100% - 400px);
  width: auto;
  margin-right: auto;
}
.experience-detail-wrapper .sharing-option .link-item:first-child {
  max-width: unset;
}
.sharing-option .link-item .dropdown .dropdown-toggle {
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  padding-left: 35px;
  transition: all 0.4s;
  padding-right: 35px;
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #f9f9f9;
  color: #201c1d;
  line-height: 24px;
  overflow: hidden;
}
.sharing-option .link-item .dropdown .dropdown-toggle:hover,
.sharing-option .link-item .dropdown .dropdown-toggle:active,
.sharing-option .link-item .dropdown .dropdown-toggle:focus,
.book-now-wrap .btn-primary:hover,
.book-now-wrap .btn-primary:active,
.book-now-wrap .btn-primary:focus,
.sharing-option .btn-primary:hover,
.sharing-option .btn-primary:active,
.sharing-option .btn-primary:focus {
  background-color: #06749f;
  outline: 0 none;
  color: #fff;
  box-shadow: unset;
}
.book-now-wrap .btn-primary:is(:hover, :active, :focus) img {
  filter: invert(100%);
}
.link-item.dropdown,
.nav-item.dropdown {
  display: flex;
  align-items: center;
}
.link-item.dropdown svg {
  margin-left: -24px;
  z-index: 10;
}
.sharing-option .link-item .dropdown .dropdown-toggle::after {
  display: none;
}
.sharing-option .link-item img {
  position: absolute;
  z-index: 1;
}
.sharing-option .link-item .icon-left {
  left: 8px;
  top: 12px;
  filter: invert(1);
}
.let-text-wrap span {
  font-size: 14px;
  font-weight: normal;
}
.experience-detail-title-wrapper .sharing-option .dropdown-menu {
  background-color: #06749f;
}
.experience-detail-title-wrapper .sharing-option .dropdown-menu .dropdown-item {
  color: #fff;
  white-space: break-spaces;
}
.experience-detail-title-wrapper
  .sharing-option
  .dropdown-menu
  .dropdown-item:hover,
.experience-detail-title-wrapper
  .sharing-option
  .dropdown-menu
  .dropdown-item:active,
.experience-detail-title-wrapper
  .sharing-option
  .dropdown-menu
  .dropdown-item:focus {
  color: #fff;
  background-color: #06749f;
}
/* .sharing-option .link-item:first-child svg {
  position: absolute;
  right: 10px;
  top: 13px;
  color: #008ec7;
} */
.sharing-option .link-item:first-child:hover svg {
  color: #fff;
}
.sharing-option .link-item .icon-right {
  right: 15px;
  transform: rotate(-90deg);
  filter: invert(1);
  top: 17px;
}
.book-now-wrap .sharing-option .location-details {
  border-radius: 12px;
  border: 1px solid var(--Gray03, #efefef);
  background: #f9f9f9;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  width: 500px;
  padding: 12px;
}
.book-now-wrap .sharing-option .map-details img:first-child {
  width: 20px;
  height: 20px;
}
.book-now-wrap .sharing-option .location-details .location-content h4 {
  color: #201c1d;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.book-now-wrap.share-like-button {
  width: fit-content !important;
}
.book-now-wrap .sharing-option .location-details .map-details .view-map {
  color: #008ec7;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-decoration: underline;
}
.book-now-wrap .sharing-option .location-details .nav-item.dropdown {
  border-radius: 6px;
  border: 1px solid #00aff4;
  width: 108px;
  height: 28px;
  padding: 8px 6px;
  margin-left: auto;
  position: relative;
}
.book-now-wrap
  .sharing-option
  .location-details
  .nav-item.dropdown
  .dropdown-placeholder {
  color: #00aff4;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
}
.book-now-wrap .sharing-option .location-details .dropdown-toggle {
  width: 100%;
}
.book-now-wrap .sharing-option .location-details .dropdown-toggle::after {
  display: none;
}
.location-header-dropdown svg {
  position: absolute;
  right: 7px;
  top: 25%;
  width: 10px;
  color: #00aff4;
}
.sharing-option .link-item + .link-item {
  margin-left: 16px;
  /* display: inline-block; */
}
.book-now-wrap .btn-primary {
  border-radius: 12px;

  background: #fff;
  color: #201c1d;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 11px 12px;
  transition: all 0.4s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}
.experience-detail-wrapper .booking-page-content {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px 30px;
  margin-bottom: 16px;
}
.cashback-section {
  background-image: url("../images//wallet-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 16px 24px;
  border-radius: 12px;
  margin: 0 0 30px;
  justify-content: flex-start;
}
.cashback-title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
}
.cashback-text {
  padding-inline-start: 20px;
}
.cashback-text p {
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}
.cashback-border {
  border-bottom: 1px solid #0000001a;
  margin-top: 24px;
}
.guarantee-text span {
  color: #201c1d;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 8px 0 0 0;
}
.guarantee-text-wrap .guarantee-text {
  width: 25%;
}
.guarantee-text-wrap .guarantee-text img {
  max-width: 30px;
}
.poster-carousel-content.hover-img {
  margin: 15px 0 10px 0;
  overflow: hidden;
  border-radius: 12px;
}
.poster-slider .poster-carousel-content.hover-img img {
  max-height: 220px;
  object-fit: cover;
}
.poster-title {
  color: #000;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  margin: 0;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #01a2e2;
}
.owl-theme .owl-dots .owl-dot span {
  margin: 0 3px;
}
.offer-text-wrap {
  flex-wrap: wrap;
}
.offer-text {
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #fff;
  padding: 16px 12px;
  width: 100%;
  max-width: calc(33.33% - 10px);
  margin-top: 12px;
}
.offer-text span {
  margin-inline-start: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 185px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.offer-wraper .total-amount {
  border-radius: 0 0 12px 12px;
  border: medium none;
  padding: 0;
}
.total-amount .footer-btn {
  color: #00aff4;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  background-color: transparent;
  border: medium none;
  padding: 4px 10px;
  width: 100%;
}
.total-amount .footer-btn:hover,
.total-amount .footer-btn:active,
.total-amount .footer-btn:focus {
  outline: 0 none;
  box-shadow: unset;
  border: medium none;
  background-color: transparent;
  color: #00aff4;
}
.offer-subtitle {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #201c1d;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 40px;
}
.title-wrap p {
  color: #848484;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  padding: 0;
  margin: 0;
}
.experience-section .title-wrap p {
  color: #666;
  font-size: 14px;
}
.event-card .offer-book {
  border-radius: 200px;
  border: 1px solid #fff;
  background: #008ec7;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 10px;
}
.event-title {
  padding: 0 16px;
}
.person-detail .each-person {
  padding: 0 16px;
  margin-bottom: 12px;
}
.person-detail .each-person p {
  padding: 0;
  margin: 0;
}
.person-detail .each-person .person-count {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.7;
}
.person-detail .each-person .person-count span {
  white-space: nowrap;
  margin-inline-end: 5px;
}
.person-detail .each-person .price-count {
  color: #201c1d;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.person-detail .each-person .price-count strong {
  font-size: 16px;
  font-weight: 600;
}
.person-detail .each-person .person-count strong {
  margin-inline-end: 5px;
}
.person-detail .each-person .price-count del {
  margin-inline-end: 5px;
}
.offer-wraper .booking-wrapper {
  padding: 20px 16px;
}
.related-media {
  max-width: 33%;
  border-radius: 8px;
  overflow: hidden;
}
.related-media img {
  width: 100%;
  aspect-ratio: 1/0.9;
}
.subtotal-wrapper .related-content p {
  padding: 0;
  margin: 0 0 4px 0;
}
.subtotal-wrapper .related-content p {
  color: #848484;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
}
.subtotal-wrapper .related-content p {
  margin-bottom: 4px;
}
.related-articles .event-title {
  padding: 0 15px;
  align-items: flex-start;
}
.related-articles .related-content {
  width: calc(100% - 33%);
  padding-inline-start: 15px;
}
.related-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 25px 0 0 0;
}
.guarantee-text .btn-primary {
  background-color: transparent;
  border: medium none;
  padding: 0;
  margin: 0 auto;
}
.guarantee-text .btn-primary:hover,
.guarantee-text .btn-primary:active,
.guarantee-text .btn-primary:focus {
  background-color: transparent;
  border: medium none;
  outline: 0 none;
  box-shadow: unset;
}
.detail-modal.detail-modal-white .modal-content {
  background: #fff;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.06);
}
.detail-modal .modal-content {
  background: linear-gradient(262.64deg, #56bee7 -1.11%, #0096d1 86.76%);
  border: 3px solid #fff;
}
.detail-modal .modal-content .modal-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.modal-schedule-content li {
  background-color: #f4f4f4;
  padding: 15px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 21px;
}
.modal-schedule-content ul li span {
  font-size: 14px;
  font-weight: 400;
  flex: 1;
  text-align: right;
  padding-left: 10px;
  color: #666;
}
.detail-modal .modal-header {
  border: medium none;
}
.modal-header .btn-close {
  opacity: 1;
}
.modal-header .btn-close:hover,
.modal-header .btn-close:active,
.modal-header .btn-close:focus {
  outline: 0 none;
  box-shadow: unset;
}
.calender-title {
  font-size: 29px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}
.modal-calender-content p {
  color: #fff;
}
.modal-calender-content label {
  color: #fff;
  width: 100%;
  margin: 15px 0 6px;
}
.modal-calender-content .form-control {
  border: 1px solid #fff;
}
.add-to-calendar-btn-wrap .add-to-calendar-checkbox {
  font-size: 16px;
  background: #e35b26;
  padding: 13px;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
  width: 100%;
}
.add-to-calendar-btn-wrap .icon-google,
.add-to-calendar-btn-wrap .icon-outlook {
  margin: 0 auto;
  width: auto;
  text-align: center;
  color: #000;
  text-decoration: underline;
  padding-bottom: 10px;
  font-size: 15px;
  max-width: 165px;
  display: none;
}
.add-to-calendar-btn-wrap .icon-google.show,
.add-to-calendar-btn-wrap .icon-outlook.show {
  display: block;
}
.detail-modal-white .modal-content .modal-title {
  font-size: 20px;
  text-align: center;
  display: block;
  width: 100%;
}
.detail-modal-white .modal-content .modal-header {
  background: #f8f8f8;
  border-bottom: 1px solid #efefef;
}
.data-inclusions p,
.data2-inclusions p {
  background-image: url("../images/green-check.svg");
  background-repeat: no-repeat;
  padding-left: 32px;
  background-position: left top;
  font-weight: 400;
  font-size: 14px;
  color: #201c1d;
  margin-bottom: 20px;
  width: 50%;
  margin-bottom: 12px;
}
.detail-modal.detail-modal-white.modals,
.modal-backdrop-dark {
  background: rgba(0, 0, 0, 0.7);
}
.data-exclusions p {
  background-image: url("../images/disable-checkbox.svg");
  background-repeat: no-repeat;
  padding-left: 32px;
  background-position: left center;
  width: 50%;
  margin-bottom: 12px;
}
.inclusion-exclusion-modal .modal-dialog-centered {
  max-width: 585px;
}
.inclusion-exclusion-modal .modal-content {
  border-radius: 24px;
}
.inclusions {
  padding: 0 20px;
}
.exclusion-title {
  border-top: 1px solid #efefef;
  padding-top: 20px;
  padding-bottom: 10px;
}
.rate-ss img {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
.rate-ss span {
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #201c1d;
}
.rate-ss p {
  font-size: 14px;
  line-height: 16px;
  padding-top: 8px;
  font-weight: 500;
}
.rating-nav li {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.rating-nav .progress {
  height: 3px;
  border-radius: 5px;
  width: 100%;
  margin-left: 10px;
}
.rating-nav .progress-bar {
  background-color: #00aff4;
}
.star-w {
  width: 150px;
  display: flex;
  justify-content: flex-end;
}
.book-now-wrap .book-btn {
  background: #f08146;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  color: #fff;
  padding: 9px;
  font-size: 12px;
  max-width: 240px;
  width: 100%;
  font-weight: 700;
  display: flex;
  min-width: 170px;
  text-align: center;
  justify-content: center;
}
.book-now-wrap .book-btn:hover,
.book-now-wrap .book-btn:active,
.book-now-wrap .book-btn:focus {
  border-color: #f08146;
  outline: 0 none;
  box-shadow: unset;
}
.related-content .text-muted img {
  margin-inline-end: 4px;
}
.related-content .text-muted {
  color: #848484 !important;
}
.related-content .clamp-single-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.subtotal-wrapper .related-content p del {
  margin-inline-end: 15px;
}
.subtotal-wrapper .related-content p strong {
  font-weight: 500;
  color: #000000;
  font-size: 12px;
}
.review-section .book-btn {
  background-color: #00aff4;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 9px;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  max-width: 170px;
}
.related-articles .subtotal-wrapper {
  padding-block: 15px;
}
.dropdown-content .form-group .form-control {
  width: 15px;
  height: 15px;
  position: static;
  opacity: 1;
  margin-left: 0;
  margin-top: 0;
  padding: 0;
  border: 1px solid #e45c20;
  border-radius: 50%;
}
.dropdown-content .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.dropdown-content .form-group .form-control:checked {
  border: 4px solid #e45c20;
}
.dropdown-content .form-label {
  font-size: 14px;
  margin-left: 10px;
  line-height: 1;
  color: #fff;
}
.sharing-option .dropdown-menu {
  background-color: #008ec7;
}
.sharing-option .dropdown-menu {
  background-color: #008ec7;
  border-radius: 12px;
  width: 100%;
  padding: 14px;
}
.review-avtar {
  height: 48px;
  width: 48px;
  overflow: hidden;
  border-radius: 50%;
}
.review-avtar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #acacac;
}
.review-by {
  /* width: calc(100% - 50px); */
  /* padding-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.replies-thread .review-header .review-by {
  width: auto !important;
  flex-direction: column;
  align-items: start;
}
.review-by .time {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.6;
  margin-bottom: 0;
}
.review-reply-text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 8px;
}
.rating-star {
  display: flex;
  align-items: center;
}
.replies .review-collapse:not(:first-child) {
  border-top: 1px solid;
  padding-top: 16px;
  border-color: rgba(0, 0, 0, 0.1);
}
.review-post-date p {
  line-height: 16px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.6;
  margin: 0;
}
.review-info {
  padding-top: 13px;
}
.review-heading {
  font-size: 16px;
  color: #000;
  margin: 0;
}
.review-info .review-text {
  padding-top: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.7;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
}
.review-counts {
  margin: 13px 0 0;
  display: flex;
  align-items: center;
}
.review-counts li {
  margin-inline-end: 43px;
}
.review-counts li .btn {
  padding: 0;
  border: medium none;
}
.review-counts li .btn:hover,
.review-counts li .btn:hover,
.review-counts li .btn:hover {
  border: medium none;
  outline: 0 none;
  box-shadow: unset;
}
.review-counts li img {
  margin-inline-end: 9px;
}
.review-counts li span {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.review-modal-content .review-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 1rem;
}
.review-modal .modal-content {
  border-radius: 6px 6px 24px 24px;
}
.review-modal .modal-header {
  background-color: #f8f8f8;
}
.review-collapse .review-btn {
  background: #f08146;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  padding: 6px 24px;
  margin-top: 16px;
}
.review-collapse .form-control:hover,
.review-collapse .form-control:active,
.review-collapse .form-control:focus,
.review-collapse .review-btn:hover,
.review-collapse .review-btn:active,
.review-collapse .review-btn:focus {
  outline: 0 none;
}
.review-collapse {
  margin-block: 16px;
}
.review-card:not(:last-child) {
  border-bottom: 1px solid #ddd;
  padding: 0 0 25px;
  margin-bottom: 25px;
}
.event-card .book-btn {
  border-radius: 200px;
  border: 1px solid #fff;
  background: #f08146;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 8px;
  white-space: nowrap;
}
.detail-video video {
  width: auto;
  max-width: 100%;
}
.single-detail-media .hover-img {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 30px;
}
.guest-content .mb-3 {
  position: relative;
}
.guest-content .form-label {
  display: block;
  text-align-last: start;
}
.guest-content .error {
  color: #dc3545;
  text-align-last: start;
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  left: 2px;
}
.form-label .required {
  color: #dc3545;
  margin-inline-start: 3px;
}
.email-modal .guest-content .form-control {
  box-shadow: unset;
}
.title.kd-write-review-title {
  color: #000;
  font-size: 22px;
  letter-spacing: 0.7px;
  margin-top: 0;
  margin-right: 0;
  padding-right: 22px;
  margin-bottom: 15px;
  font-weight: 600;
}
.rating-faces-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
.rating-faces-wrap .radio-type {
  margin-right: 13px;
}
.rating-faces-wrap .radio-type .label_item {
  opacity: 1;
  cursor: pointer;
}
.radio-type:last-child {
  display: inline-block;
}
.radio-container input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
.write-review-modal .modal-dialog {
  max-width: 800px;
}
.rate-class {
  display: flex;
}
.review-form .radio-type .radio_item {
  display: none !important;
}
.review-form span {
  color: #666;
  font-weight: 500;
  font-size: 15px;
}
.review-form .radio-type span {
  text-align: center;
  display: block;
  margin-top: 2px;
}
.radio-container span.yes {
  display: none;
}
.radio-container input:checked ~ span.yes {
  display: block !important;
}
.rating-faces-wrap .label_item img {
  width: 40px;
  height: auto;
  margin: 0 auto;
}
.review-form .rating-faces-wrap .radio-type span.no,
.review-form .rating-faces-wrap .radio-type span.yes {
  padding-bottom: 3px;
}
.radio-container input:checked ~ span.no {
  display: none !important;
}
.rating-faces-wrap .radio-type .radio-container .rate-text {
  color: #222;
  font-weight: 500;
  text-align: center;
  display: block;
  margin-top: 2px;
}
.review-form input,
.review-form textarea {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px 0;
  outline: 0;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  border-radius: 0;
}
.footer-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.footer-action .btn-primary {
  background-color: #ffde45;
  border-radius: 5px;
  max-width: 160px;
  letter-spacing: 0.7px;
  height: 45px;
  color: #000;
  font-size: 16px;
  padding: 0;
  border-color: #ffde45;
  width: 160px;
}
.footer-action .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}
.footer-action .switch input {
  display: none;
}
.footer-action .switch-name {
  display: flex;
  align-items: center;
}
.footer-action .switch-name span {
  margin-inline-start: 4px;
}
.footer-action .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  width: 100%;
  margin: 0px auto;
  transition: 0.4s;
}
.footer-action .slider.round {
  height: 24px;
  border-radius: 24px;
}
.footer-action .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.footer-action .slider.round:before {
  border-radius: 50%;
}
.footer-action input:checked + .slider:before {
  transform: translateX(32px);
}
.footer-action input:checked + .slider {
  background-color: #2196f3;
}
.file-input-block {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  max-width: 100%;
  min-height: 281px;
  padding: 30px 15px;
  background-color: #fff;
  background-image: none;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #ccc;
}
.file-input-block p {
  color: #b6b6b6;
  font-size: 14px;
  line-height: 1.28;
}
.file-input-block p > strong {
  color: #484848;
  text-decoration: underline;
  font-weight: inherit;
  font-family: inherit;
}
.file-input-block .file-icon-block {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  height: 130px;
  width: 130px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin: 35px auto;
  border: 1px dashed #ccc;
}
.file-input-block .file-icon-block .file-icon {
  display: block;
  width: 40px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.file-input-block .file-icon-block .file-icon img {
  width: 100%;
}
.file-input-block .add-photos {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 0;
}
.file-input-block .gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 35px -15px 5px;
}
.file-input-block .gallery .gallery-image {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
  position: relative;
  margin-bottom: 30px;
}
.file-input-block .gallery .gallery-image img {
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
}
.file-input-block .gallery .gallery-image .remove-thumb {
  background-image: url(https://webbah.kidzapp.com/assets/img/small-cross-icon-kd-new.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #e35b26;
  width: 20px;
  height: 20px;
  background-size: 10px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: 6px;
  cursor: pointer;
}
/* conflicted CSS Start */
.booking-media .hover-img {
  overflow: hidden;
  border-radius: 12px;
}
.review-section .review-modal-btn {
  width: 100%;
  background-color: #fff;
  border: medium none;
  /* border-top: 1px solid #0000001a; */
  /* margin-top: 24px; */
  /* padding: 20px 0 0 0; */
  color: #201c1d;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
  margin-bottom: 0;
  border-radius: 0;
  padding-left: 0;
}
.review-section .review-modal-btn:hover,
.review-section .review-modal-btn:focus,
.review-section .review-modal-btn:active {
  background-color: #fff;
  border: medium none;
  /* border-top: 1px solid #0000001a; */
  color: #201c1d;
  box-shadow: unset;
  outline: 0 none;
}
.double-border-section {
  border-bottom: 1px solid #0000001a;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
/* .review-section .review-modal-btn .fa-chevron-right {
  transform: rotate(90deg);
} */
.review-modal-btn p span.text-muted {
  font-weight: normal;
  font-size: 12px;
  display: block;
  text-align: left;
}
.modal-calender-content select.form-control + select.form-control {
  margin-top: 1rem;
}
/* conflicted CSS End */
.missing-info-wrap .form-group {
  display: flex;
  align-items: center;
}
.missing-info-wrap .form-group input.form-control {
  max-width: 14px;
  height: 14px;
  min-height: 14px;
  padding: 10px;
  border: 2px solid #00aff4;
  margin-inline-end: 10px;
}
.missing-info-wrap .form-label {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}
.missing-info-wrap textarea.form-control {
  border-radius: 12px;
  background: #ecf4f8;
  resize: none;
  border-color: #ecf4f8;
  height: 72px;
  margin: 20px 0;
}
.submit-wrap .submit-info {
  border-radius: 12px;
  border: 2px solid #fff;
  background-color: #00aff4;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  border-color: #00aff4;
  padding: 14px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.paid-amount-status {
  background-color: #ecf8f1;
  padding: 12px 15px;
}
.booking-status-wrapper p {
  margin: 0;
}
.booking-status-wrapper .paid-amount-status p {
  margin: 0;
  color: #27ae60;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  justify-content: center;
}
.booking-status-wrapper {
  border: 1px solid #efefef;
  box-shadow: 46px 12px 20px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  border-radius: 12px;
  padding-top: 16px;
}
.booking-status-wrapper img {
  margin: 15px 0;
}
.add-to-calender {
  border-radius: 12px;
  border: 1px solid #efefef;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  margin-top: 16px;
  padding: 12px;
}
.add-cal-wrap {
  display: flex;
  align-items: center;
}
.cal-text-wrap p {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}
.cal-icon-wrap {
  height: 32px;
  width: 32px;
  background-color: #e45c20;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 12px;
}
.booking-confirmed {
  background-image: url("../images/cashback-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 16px 24px;
  margin: 16px 0;
}
.confirmed-title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
}
.booking-confirmed p {
  margin: 0;
  color: #fff;
}
.confirm-booking-page-content .activity-media {
  max-width: 200px;
}
.confirm-booking-page-content .activity-card {
  transform: unset;
  margin-top: 30px;
  padding: 16px;
  position: relative;
}
.booking-page-content.booking-service-section ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.booking-page-content.booking-service-section ul li svg {
  color: #00aff4;
}
.booking-page-content.booking-service-section ul li span {
  color: #201c1d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
/* Booking detail page CSS End */
/* Blog Page CSS Start */
.blog-title {
  color: #000;
  font-size: 40px;
  font-weight: 700;
}
.blog-search-wrap {
  max-width: 330px;
  width: 100%;
}
.blog-search-wrap .form-control {
  background-color: #f8f8f8;
  border: 1px solid #e9e9e9;
  height: auto;
  min-height: 56px;
  padding-right: 54px;
}
.blog-search-wrap .form-group {
  position: relative;
}
.blog-search-wrap .search-icon {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 48px;
  height: 48px;
  padding: 0;
  border: medium none;
  background-color: transparent;
}
.blog-search-wrap .search-icon:hover,
.blog-search-wrap .search-icon:active,
.blog-search-wrap .search-icon:focus {
  outline: 0 none;
  box-shadow: unset;
  border: medium none;
  background-color: transparent;
}
.blog-search-wrap .search-icon img {
  width: 100%;
  height: 100%;
}
.blog-pills .nav-item .nav-link {
  border-bottom: 3px solid #fff;
  padding: 12px 20px;
  overflow: hidden;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.6;
  border-radius: 0;
}
.blog-pills .nav-item:first-child .nav-link {
  padding-inline-start: 0;
}
.blog-page-card {
  background-color: #fff;
  border: 1px solid #efefef;
  box-shadow: 10px 12px 20px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  border-radius: 12px;
  overflow: hidden;
  height: calc(100% - 30px);
}
.blog-tab-content
  .infinite-scroll-component__outerdiv
  .infinite-scroll-component {
  overflow-y: auto;
  overflow-x: hidden !important;
}
.blog-media {
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  position: relative;
}
.blog-media a {
  display: block;
}
.blog-page-card .blog-media img {
  transition: all 0.4s;
  border-radius: 0 0 12px 12px;
}
.blog-page-card:hover .blog-media img {
  transform: scale(1.3);
  transition: 0.2s linear;
}
.blog-pills .nav-item .nav-link.active {
  background-color: transparent;
  border-bottom: 3px solid #00aff4;
  color: #00aff4;
  font-weight: 600;
}
.blog-card-content {
  padding: 20px;
}
.blog-pills {
  border-bottom: 1px solid #ccc;
  margin-bottom: -1px;
  margin-top: 30px;
}
.blog-content-wrap {
  margin-bottom: 30px;
}
.blog-date {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 0px 0 12px 0;
}
.blog-text {
  color: #201c1d;
  font-size: 14px;
  line-height: 20px;
}
.blog-date img {
  margin-inline-end: 5px;
}
.blog-text .read-more {
  color: #00aff4;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.team-flag {
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #fcc734;
  padding: 6px 15px;
  border-radius: 0 6px 6px 0;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.blog-detail-banner {
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px;
  margin-bottom: 28px;
  background-image: url("https://s3.me-south-1.amazonaws.com/testimagesbah.kidzapp.com/profilepics/1699265514880blog-banner.webp");
}
.blog-detail-wrapper.spacing-top {
  margin-top: 87px;
}
.blog-detail-search {
  max-width: 550px;
  margin-inline: auto;
}
.blog-detail-search .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-detail-search .icon-group .icon-left {
  left: 19px;
  top: 15px;
}
.blog-detail-search .form-group .form-control {
  padding: 10px 10px 10px 45px;
}
.blog-detail-search .form-group .search-icon {
  background-color: #00adee;
  padding: 10px 15px;
  border-radius: 5px;
  height: 45px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  outline: none;
  box-shadow: none;
  margin-left: 10px;
  border: 1px solid #00adee;
  width: 100%;
  max-width: 110px;
}
.blog-detail-search .form-group .search-icon:hover,
.blog-detail-search .form-group .search-icon:active,
.blog-detail-search .form-group .search-icon:focus {
  background-color: #484848;
  border-color: #484848;
  outline: 0 none;
  box-shadow: unset;
}
.back-btn-blog a {
  color: #484848;
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
}
.back-btn-blog span {
  margin-inline-end: 10px;
}
.blog-detail-image img {
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
}
.blog-detail-content h3 {
  line-height: 40px;
  color: #484848;
  font-size: 36px;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 600;
  white-space: pre-wrap;
  word-break: break-all;
}
.blog-detail-content i {
  white-space: pre-wrap;
  word-break: break-all;
}
.blog-detail-title {
  line-height: 33px;
  color: #484848;
  font-size: 36px;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 600;
}
.related-bllog-title {
  color: #222;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.1px;
  margin-bottom: 0px;
  font-weight: 600;
  margin-top: 40px;
}
.author-info {
  padding: 20px 0 10px;
}
.author-info li span,
.author-info li a {
  font-size: 16px;
  line-height: 20px;
  color: #222;
}
.author-info li {
  padding: 5px 0;
  margin-inline-end: 15px;
}
.author-info li img {
  margin-inline-end: 10px;
}
.blog-detail-content p {
  font-size: 18px;
  color: #666;
  padding: 10px 0;
  margin-bottom: 20px;
}
.elementor-divider {
  padding-top: 32px;
  line-height: 0;
  font-size: 0;
  padding-bottom: 28px;
}
.elementor-divider-separator {
  display: inline-block;
  border-top-style: dotted;
  border-top-width: 3px;
  border-top-color: #14bbef;
  width: 100%;
}
.related-author img {
  max-width: 18px;
  margin-inline-end: 10px;
}
.related-author p {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #222;
  margin: 7px 10px 10px 0;
}
.related-author p,
.related-author p a {
  color: #222;
}
.related-blog-content .blog-page-card {
  padding: 10px;
  margin-bottom: 10px;
}
.related-blog-content .blog-media {
  border-radius: 10px;
}
.related-blog-content .blog-card-content {
  padding: 0px;
}
.related-blog-title a {
  min-height: 57px;
  display: block;
  color: #222;
  padding-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  margin: 20px 0 10px;
}
.blog-detail-wrapper .related-blog-title a {
  min-height: 39px;
}
.blog-page-card .blog-text a {
  color: #00acef;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  font-weight: 500;
}
.lightbox-media img {
  width: auto;
  max-width: 100%;
}
.lightbox-modal .modal-dialog {
  max-width: 900px;
  width: 90%;
}
.lightbox-modal .modal-dialog .modal-body {
  padding: 0;
}
.lightbox-modal .modal-dialog .modal-body img {
  border-radius: 8px;
  overflow: hidden;
}
.lightbox-modal .modal-dialog .modal-header .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0;
  background-size: 14px;
}
.lightbox-content .carousel-control-next,
.lightbox-content .carousel-control-prev {
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #fff;
  opacity: 1;
}
.lightbox-content .carousel-control-next .carousel-control-next-icon,
.lightbox-content .carousel-control-prev .carousel-control-prev-icon {
  height: 20px;
  width: 20px;
}
.lightbox-modal .modal-dialog .modal-header {
  padding: 0;
  border: medium none;
}
.blog-posted-by {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.blog-posted-by .dropdown-menu {
  display: flex;
  right: 0;
  position: absolute;
  justify-content: flex-end;
  top: -10px;
}
.blog-detail-content .related-author p {
  margin-inline-end: 15px;
}
.blog-detail-content .share-icons .btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #00acef;
  min-width: 108px;
  height: 40px;
  border-radius: 5px;
  transition: 0.3s all;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding: 6px 12px;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}
.blog-detail-content .share-icons .btn-primary:hover,
.blog-detail-content .share-icons .btn-primary:hover,
.blog-detail-content .share-icons .btn-primary:hover {
  background-color: #965da4;
  box-shadow: unset;
  color: #fff;
}
.share-icons.dropdown .btn-primary img {
  max-width: 22px;
  margin-inline-end: 5px;
}
.blog-detail-content .share-option .dropdown-menu {
  display: flex;
  bottom: 0;
  right: 0;
}
.spin-colored-loader {
  position: relative;
  height: 150px;
  background-image: url("../images/Spinner3.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-size: contain;
}
.wishlist-item {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;
  margin-top: 25px;
}
.wishlist-media img {
  max-width: 100%;
}
.wishlist-media {
  position: relative;
  height: 0;
  padding-bottom: 53%;
  background-size: cover;
  width: 100%;
}
.wishlist-media .wishlist-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 1;
  visibility: visible;
  height: 100%;
  border-radius: 5px 5px 0 0;
}
.wishlist-media .deal-icon {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  width: 60px;
}
.blue-bg-text {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 0;
}
span.blue-bg-text label {
  display: inline-block;
  margin-bottom: 0;
  background: #14b9e9;
  padding: 3px 10px;
  position: relative;
  letter-spacing: 1.2px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  padding-left: 5px;
}
.blue-bg-text label::before {
  content: "";
  position: absolute;
  display: inline-block;
  border: 14px solid #14b9e9;
  z-index: 1;
  top: 0;
  left: -15px;
  border-right-width: 5px;
  border-left-color: transparent;
}
.wishlist-icon {
  position: absolute;
  top: 3px;
  right: 0px;
  z-index: 1;
  max-width: 40px;
}
.rating-info {
  bottom: 10px;
  right: 10px;
  position: absolute;
  z-index: 1;
}
.rating-info .rating-count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
}
.rating-info .rating-count img {
  margin-inline-end: 4px;
}
.wishlist-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 21px;
  color: #222;
}
.wishlist-content {
  padding: 15px;
}
.wishlist-content p {
  margin: 0;
  color: #965ca5;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}
/* Blog Page CSS End */
/* Awards Page CSS Start */
.awards-wrapper.spacing-top {
  margin-top: 104px;
}
.awards-wrapper img {
  width: auto;
  max-width: 100%;
}
.journey-title {
  text-align: center;
  color: #484848;
  font-size: 42px;
  font-weight: 600;
  line-height: 46px;
  margin-bottom: 30px;
}
.journey-section {
  padding-top: 55px;
}
.award-patrner-section .nominate-button {
  padding: 10px 29px;
  font-size: 30px;
  border-radius: 38px;
  background-color: #e35b26;
  border-color: #e35b26;
  color: #fff;
  font-weight: 500;
}
.award-patrner-section .nominate-button:hover {
  color: #e35b26;
  background-color: #fff;
  border-color: #e35b26;
}
.award-patrner-section {
  padding: 90px 0 70px;
}
.video-section {
  margin: 48px 0;
}
.video-section iframe {
  height: 500px;
}
.partner-media a {
  display: block;
}
.awards-wrapper .partner-media img {
  width: 100%;
}
.award-winners-section {
  background-color: #fafafa;
  padding: 54px 0 0px;
}
.winners-list-wrap {
  padding: 25px 23px 24px;
  margin-bottom: 40px;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  background: #fff;
}
.winner-section-inner::after {
  content: "";
  position: absolute;
  height: 90%;
  width: 1px;
  background: #b7b7b7;
  right: -35%;
  left: auto;
  top: 0;
}
.winner-section-inner + .winner-section-inner::after {
  display: none;
}
.category-ion {
  width: 93px;
  margin-inline-end: 10px;
  display: block;
}
.winner-category {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.winner-cat-title {
  font-size: 24px;
  line-height: 34px;
  text-align: left;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  color: #414141;
  font-weight: 600;
}
.winner-section-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 37%;
  max-width: 37%;
  position: relative;
  flex-wrap: nowrap;
}
.winner-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
  justify-content: space-between;
}
.winner-user {
  display: flex;
  justify-content: center;
  align-items: center;
}
.winner-user p {
  margin: 0;
}
.winner-user p {
  color: grey;
  font-size: 18px;
  font-weight: 500;
  margin: 0 5px 0 10px;
  text-transform: capitalize;
  line-height: 23px;
}
.winner-user p span {
  font-size: 12px;
}
.gold-block .winner-user p span {
  color: #d4af37;
}
.winner-prize {
  width: 94px;
}
.awards-banner {
  padding-top: 70px;
}

.awards-banner img {
  width: 100%;
  max-width: 100%;
}
.awards-wrapper .kids-feed-media img {
  width: 100%;
  max-width: 100%;
}
.awards-category-section p {
  font-size: 22px;
  color: #414141;
  text-align: center;
  line-height: 26px;
  margin-bottom: 27px;
}
.awards-category-section p.warning-text {
  color: #e35b27;
  margin-bottom: 44px;
}
.awards-category-section .container {
  max-width: 940px !important;
  padding: 0 15px;
  margin: 0 auto;
}
.nominee-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.nominee-form input {
  border: 1px solid #999;
  width: 100%;
  height: 36px;
  padding: 0 15px;
  flex: 0 0 81%;
  max-width: 81%;
  border-radius: 5px 0 0 5px;
  border-right: none;
  font-size: 14px;
  outline: none;
}
.awards-category-section .search-nominee-btn {
  flex: 0 0 6.5%;
  max-width: 6.5%;
  border-radius: 0 5px 5px 0;
  background: url("../images/search-flat.webp") #00adee no-repeat;
  background-size: 23px;
  background-position: center center;
  border: 1px solid #999;
  border-left: none;
  outline: 0;
  font-weight: 700;
  color: #fff;
  height: 36px;
}
.awards-category-section .reset-btn {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  display: inline-flex;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.08px;
  color: #00adee;
}
.award-cat-title {
  font-size: 24px;
  font-weight: 600;
  color: #414141;
  width: 100%;
  margin-bottom: 17px;
  line-height: 42px;
}
.awards-categories-wrapper {
  padding: 35px 0 25px;
}
.awards-categories-accordion .card {
  padding: 15px 30px 10px;
  border-radius: 10px !important;
  box-shadow: -1px 1px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid #b7b7b7 !important;
  margin-bottom: 30px;
  overflow: hidden;
  background-color: #fff;
}
.awards-categories-accordion .card-header {
  padding: 0;
  background-color: #fff;
  border: medium none;
}
.awards-categories-accordion .card-header .btn {
  padding: 0;
  width: 100%;
  text-align: left;
  position: relative;
  border: medium none;
}
.awards-categories-accordion .card-header .btn:hover,
.awards-categories-accordion .card-header .btn:active,
.awards-categories-accordion .card-header .btn:focus {
  outline: 0 none;
  box-shadow: unset;
  border: medium none;
}
.awards-categories-accordion .card-header .btn img {
  margin-right: 20px;
  max-width: 100px;
  height: auto;
  width: 100%;
}
.awards-categories-accordion .card-header .btn span {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  color: #414141;
  margin-bottom: 0;
  max-width: 75%;
  letter-spacing: 0.9px;
}
.awards-categories-accordion .card-header .btn::after {
  position: absolute;
  content: "";
  background-image: url("../images/gray-dropdown-icon.webp");
  width: 25px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
.awards-categories-accordion .card .card-body {
  padding: 20px 0;
}
.nominee-lists {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
}
.nominee-lists .nominee-details {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  flex: 0 0 50%;
  max-width: 50%;
  font-size: 14px;
  line-height: 21px;
}
.nominee-lists .nominee-details:nth-child(odd) {
  padding-right: 80px;
}
.nominee-lists .nominee-details .nominee-radio-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.nominee-lists .nominee-details input[type="radio"] {
  position: absolute;
  opacity: 0;
  display: none;
}
.nominee-lists .nominee-details input[type="radio"] + label {
  position: relative;
  padding-left: 40px;
  color: #484848;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.14px;
  font-weight: 400;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  padding-right: 10px;
}
.nominee-lists .nominee-details input[type="radio"] + label:before {
  content: "";
  background: 0 0;
  border-radius: 50%;
  border: 2px solid #bcbcbc;
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  left: 0;
}
.nominee-details input[type="radio"]:checked + label:before {
  box-shadow: inset 0 0 0 4px #fff;
  border: 2px solid #00adef;
  background: #00adef;
}
.nominee-lists .nominee-details .nominee-radio-wrapper .nominee-logo {
  max-width: 100px;
  width: 100%;
}
.more-infor-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.more-infor-wrap .your-vote {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
}
.more-infor-wrap .your-vote label {
  margin-bottom: 0;
  color: #858585;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: -0.04px;
  font-weight: 400;
}
.more-infor-wrap .your-vote label span {
  margin-bottom: 0;
  color: #858585;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: -0.04px;
  font-weight: 400;
}
.more-infor-wrap .your-vote img {
  margin-left: 42px;
  max-width: 100px;
}
.votenow-modal .modal-dialog {
  max-width: 600px;
}
.votenow-modal .modal-title.h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: #484848;
  text-align: center;
}
.votenow-modal .form-control:hover,
.votenow-modal .form-control:active,
.votenow-modal .form-control:focus {
  outline: 0 none;
  box-shadow: unset;
}
.awards-categories-wrapper .btn-primary.nominate-button {
  background-color: #e35b26;
  border-color: #e35b26;
  outline: 0;
  border-radius: 5px;
  width: auto;
  margin: 0 auto;
  color: #fff;
  letter-spacing: 0.5px;
  background-image: url("../images/contact-btn-back.png");
  background-repeat: no-repeat;
  background-position: right center;
  font-size: 22px;
  font-weight: 600;
  height: 73px;
  line-height: 75px;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 88px;
}
.required {
  color: #dc3545;
  margin-inline-start: 3px;
  display: inline-block;
}
.votenow-modal .form-label {
  text-align: start;
  display: block;
}
.votenow-modal .form-check {
  text-align: start;
}
.vote-option-btn .btn-primary {
  background-image: url("../images/contact-btn-back.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-color: #e35b26;
  border: 1px solid #e35b26;
  max-width: 300px;
  width: 100%;
  padding: 9.5px 0;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  border-radius: 5px;
  margin: 13px auto 14px;
  display: block;
  height: auto;
  cursor: pointer;
}
.vote-option-btn .btn-secondary {
  background-color: #00adee;
  border-color: #00adee;
  border-radius: 5px;
  height: auto;
  padding: 10.5px 0;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
  display: block;
  border: none;
  text-align: center;
  margin-top: 20px;
}
.votenow-modal .error {
  color: #dc3545;
  display: block;
  text-align: start;
  margin: 5px 0;
}
.voting-success-banner {
  height: 186px;
  text-align: center;
  margin: 0 auto 10px;
}
.voting-success-wrapper.spacing-top {
  margin-top: 103px;
}
.voting-success-banner img {
  width: 100%;
}
.nominatation-closed {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 100px;
}
.nominatation-closed.voting-message img {
  margin-bottom: 45px;
  width: 200px;
  max-width: 100%;
}
.nominatation-closed .title {
  color: #46b06e;
  font-weight: 600;
  font-size: 46px;
  line-height: 50px;
  margin-bottom: 50px;
}
.awards-form-section .nominatation-closed p {
  font-size: 28px;
  margin-bottom: 20px;
  line-height: 21px;
  color: #484848;
}
.voting-message p.vote-submitted {
  line-height: 34px;
  padding: 15px 100px 0;
}
.vote-success .awards-social-url {
  margin-top: 80px !important;
}
.vote-success .awards-social-url {
  width: 100%;
  margin: 10px auto 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 0;
}
.vote-success .awards-social-url li {
  list-style: none;
  margin: 0 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}
.vote-success .awards-social-url li img {
  width: 40px;
  height: 40px;
  max-width: 100%;
  margin: 0;
}
.vote-success .awards-social-url li:nth-child(1) {
  background-color: #f08146;
}
.vote-success .awards-social-url li:nth-child(2) {
  background-color: #45b278;
}
.vote-success .awards-social-url li:nth-child(3) {
  background-color: #a874b4;
}
.tv-cards-carousel .owl-stage {
  display: flex;
}
.tv-card-item {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 5px 20px;
  height: calc(100% - 20px);
}
.tv-title {
  font-size: 44px;
  font-weight: 500;
  line-height: 1.17;
  color: #484848;
  margin-bottom: 42px;
}
.tv-card-header {
  position: relative;
  overflow: hidden;
}
.video-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 46px;
  z-index: 1;
}
.tv-cards-section img {
  max-width: 100%;
}
.preview-list li img {
  width: 22px;
  height: 22px;
  margin-inline-end: 5px;
}
.card-header-image {
  background-image: url("../images/video-card-image.png");
  height: 0;
  padding-bottom: 53%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px 5px 0 0;
}
.tv-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preview-list {
  display: flex;
  align-items: center;
}
.preview-list li + li {
  margin-inline-start: 25px;
}
.preview-list a {
  display: flex;
  align-items: center;
}
.like-span {
  color: #fff;
  font-size: 14px;
  line-height: 1.28;
  background-color: #b6b6b6;
  border-radius: 12px;
  padding: 4px 8px;
  display: inline-block;
  min-width: 40px;
  text-align: center;
}
.share-option .btn {
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: medium none;
  color: #666;
}
.share-option .btn:hover,
.share-option .btn:active,
.share-option .btn:focus {
  outline: 0 none;
  box-shadow: unset;
  background-color: transparent;
  border: medium none;
  color: #666;
}
.video-detail-main .share-option.share-icons {
  margin-left: 25px;
}
.share-option img {
  width: 22px;
  height: 22px;
  margin-inline-start: 5px;
}
.tv-flags a {
  font-size: 14px;
  line-height: 18px;
  color: #965da4;
  margin-top: 5px;
}
.tv-card-footer {
  padding: 14px 21px 14px 14px;
  justify-content: space-between;
  position: relative;
}
.tv-card-content {
  border-bottom: 1px solid #e5e5e5;
  padding: 13px;
}
.tv-cards-title a {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tv-cards-title-main {
  font-size: 36px;
  line-height: 1.17;
  color: #484848;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 20px;
}
.video-item {
  height: 0;
  position: relative;
  padding-bottom: 56.4%;
  border-radius: 5px 5px;
  overflow: hidden;
}
.video-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.video-item .play-icon {
  width: 132px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.video-item .pause-icon {
  width: 45px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.share-video {
  position: absolute;
  right: 10px;
  top: 10px;
  height: auto;
  z-index: 11;
}
.add-to-fav {
  position: absolute;
  right: 50px;
  top: 10px;
  height: auto;
  width: 40px;
  z-index: 11;
}
.add-to-fav .btn-transparent,
.share-video .btn-transparent {
  background-color: transparent;
  border: medium none;
  padding: 0;
}
.share-icons .dropdown-menu {
  background-color: transparent;
  border: medium none;
  width: 52px;
  min-width: unset;
  text-align: center;
  z-index: 0;
  display: none;
  justify-content: flex-start;
}
.video-detail-footer .share-video.share-icons.dropdown {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
}
.video-detail-footer
  .share-video.share-icons.dropdown
  .dropdown-menu.show.dropdown-menu-right {
  justify-content: flex-start;
  top: -16px;
  left: 50px;
}

.video-detail-footer .share-video.share-icons.dropdown .btn.btn-primary {
  margin-left: 20px;
}
.video-detail-footer .share-video.share-icons.dropdown .btn.btn-primary img {
  filter: invert(0.3);
}
.tv-cards-carousel .share-icons .dropdown-menu {
  justify-content: flex-end;
  flex-direction: column;
}
.video-carousel-wrapper .dropdown-menu {
  flex-direction: column;
  justify-content: flex-end;
  right: -8px;
}
.share-icons .dropdown-menu.show {
  display: flex;
}
.share-icons .dropdown-menu li {
  padding: 2px 6px;
}
.share-icons .dropdown-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #00acef;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: 0.3s all;
  padding: 0;
}
.share-icons .dropdown-item:hover,
.share-icons .dropdown-item:active,
.share-icons .dropdown-item:focus {
  background-color: #965da4;
}
.share-icons .dropdown-item img {
  width: 20px;
  height: 20px;
}
.share-option .dropdown-menu {
  bottom: 20px;
  right: -10px;
}
.share-icons.share-option .dropdown-item {
  width: 30px;
  height: 30px;
}
.share-icons.share-option .dropdown-item img {
  margin: 0;
  max-width: 15px;
}
.video-item {
  height: 0;
  position: relative;
  padding-bottom: 56.4%;
  border-radius: 5px 5px;
  overflow: hidden;
}
/* .video-item {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    background-image: linear-gradient(to top,#000 0,rgba(0,0,0,0) 100%);
    opacity: .75;
    border-radius: 5px;
} */
.video-detail-main .video-item {
  margin-top: 40px;
}
.video-detail-footer {
  display: flex;
  border-top: none;
  margin-bottom: 28px;
  padding: 29px 0 20px 24px;
  position: relative;
}
.video-detail-each-comment {
  display: flex;
  margin-bottom: 20px;
}
.video-comment-image {
  max-width: 50px;
  flex: 0 0 50px;
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  display: inline-block;
}
.video-comment-image img {
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.video-comment-content {
  max-width: calc(100% - 50px);
  flex: 0 0 calc(100% - 50px);
  padding-left: 14px;
}
.video-comment-user {
  color: #484848;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}
.video-comment-content p {
  color: #484848;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-bottom: 0;
  word-break: break-word;
}
.video-comment-content .form-control {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  color: #b6b6b6;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding: 15px;
  width: 100%;
  outline: none;
}
.video-detail-title {
  color: #484848;
  letter-spacing: 0.72px;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
}
/* Awards Page CSS End */
/* Privacy Terms CSS Start */
.privacy-page .privacy-title {
  font-size: 50px;
  line-height: 56px;
  color: #484848;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
}
.privacy-page p,
.privacy-page ul li,
.privacy-page ul li a {
  font-size: 16px;
  line-height: 21px;
  color: #666;
  margin-bottom: 20px;
}
.privacy-page .promise,
.privacy-page .promise li {
  padding-left: 20px;
  list-style: disc;
}
.privacy-page h2 {
  font-size: 26px;
  line-height: 30px;
  color: #484848;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 400;
}
/* Privacy Terms CSS End */
/* Confirm booking CSS Start */
.confirm-booking-title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.confirm-booking-page-content .booking-media {
  margin: 20px 0;
}
.user-contact-detail li a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-inline-start: 5px;
}
.confrim-name {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-inline-start: 15px;
  margin-bottom: 0;
}
.promo-card {
  margin-top: 24px;
}
.promo-form-wrap {
  border-radius: 12px;
  background-color: #e3f2f8;
  padding: 16px;
}
.promo-title {
  color: #0e0e0e;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.promo-form-wrap .form-group .promo-btn {
  border-radius: 8px;
  background: linear-gradient(234deg, #56bee7 0%, #0096d1 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  border: medium none;
  padding: 4px 14px;
  position: absolute;
  right: 5px;
  bottom: 6px;
}
.promo-form-wrap .form-group {
  position: relative;
  margin-bottom: -30px;
}
.booking-sidebar .promo-form-wrap .form-control {
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #fff;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}
.schedule-place {
  position: relative;
  margin-top: 50px;
  background-image: url("../images/Subtract.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 240px;
}
.schedule-place .schedule-content {
  padding: 30px 44px;
  min-height: 220px;
}
.schedule-bg {
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 100%;
  max-height: 250px;
}
.schedule-bg img {
  height: 100%;
  width: 100%;
}
.schedule-content {
  position: relative;
}
.schedule-content::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 75%;
  transform: translate(-50%, -50%);
  background-color: #848484;
  opacity: 0.1;
}
.total-amount .cashback-text {
  display: block;
  text-align-last: end;
  font-size: 9px;
}
.total-amount .cashback-text img {
  max-width: 10px;
}
.schedule-content {
  padding: 25px 40px 35px;
}
.schedule-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.schedule-content .schedule-location {
  color: #000;
  font-size: 14px;
  line-height: 16px;
  margin: 12px 0 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.schedule-content .schedule-location img ~ * {
  opacity: 0.6;
}
.schedule-time span {
  color: #27ae60;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.schedule-content .schedule-time {
  margin-top: 50px;
  margin-bottom: 0;
  margin-right: 30px;
}
.schedule-content .schedule-time + .schedule-time {
  margin-top: 12px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.schedule-content .schedule-time p {
  opacity: 0.6;
  margin: 0;
}
.schedule-clock span {
  color: #000;
  font-size: 14px;
  opacity: 0.6;
  margin-inline-start: 3px;
}
.schedule-clock {
  margin-top: 5px;
  margin-bottom: 0;
}
.schedule-clock img {
  max-width: 15px;
}
.product-confirm-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 8px 16px;
  padding-block: 12px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.subtotal-wrapper .total-amount:not(.subtotal-amount) p:first-child {
  padding-bottom: 0;
}
.subtotal-wrapper .total-amount p + p {
  padding-block: 0;
}
.product-total p {
  color: #201c1d;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.6;
  margin: 0;
  padding-block: 0;
}
.confirm-booking-wrapper .buy-section .btn-primary {
  margin-bottom: 20px;
}
.promo-form-wrap .form-group .promo-remove {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  border: medium none;
  padding: 4px 8px;
  position: absolute;
  right: 75px;
  bottom: 6px;
  background-color: #ffeaea;
  border-radius: 8px;
}
.promo-form-wrap .form-group.promo-applied-group .promo-btn {
  border-radius: 8px;
  background: linear-gradient(234deg, #56bee7 0%, #0096d1 100%);
  color: #fff;
}
.payment-wrapper {
  border-radius: 12px;
  border: 1px solid #efefef;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}
.payment-logo {
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #fff;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 15px;
}
.payment-logo.add-card-btn {
  border: 1px solid #47b7e3;
  color: #47b7e3;
  font-weight: 500;
  transition: all 0.4s;
}
.payment-logo.add-card-btn:hover,
.payment-logo.add-card-btn:active,
.payment-logo.add-card-btn:focus {
  border: 1px solid #47b7e3;
  background-color: #47b7e3;
  color: #fff;
}
.payment-wrapper + .payment-wrapper {
  margin-top: 20px;
}
.payment-form {
  margin-top: 20px;
}
/* Confirm booking CSS End */
/* Filter Page Design CSS Start */
.featured-section .slick-slide {
  padding-right: 20px;
  padding-bottom: 30px;
}
/* New Filter Design Start */
.activity-filters {
  background-color: #008ec7;
  padding: 17px 0 13px;
}
.search-activity .icon-group .form-control {
  min-height: unset;
  padding: 4px 15px;
  min-width: 170px;
  width: 100%;
  max-width: 200px;
}
.search-activity .icon-group .btn-primary {
  position: absolute;
  right: 10px;
  top: 4px;
  background-color: transparent;
  border: medium none;
  color: #00aff4;
  width: 15px;
  text-align: center;
  font-size: 17px;
  padding: 0;
}
.dropdown-activity .dropdown-toggle::after {
  border: medium none;
  background-image: url("../../assets/images/dropdown-arrow-grey.png");
  background-repeat: no-repeat;
  height: 22px;
  width: 12px;
  background-position: -1px 9px;
  margin-inline-start: 8px;
  margin-inline-end: 7px;
  transition: all 0.4s;
  position: absolute;
  right: 7px;
}
.search-activity .icon-group .btn-primary.close-search {
  display: none;
}
.dropdown-activity .dropdown-toggle {
  background-color: #fff;
  color: #666;
  width: 140px;
  margin-inline-start: 10px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}
.dropdown-activity .dropdown-item {
  padding: 6px;
  line-height: 22px;
  font-size: 14px;
  color: #484848;
  font-weight: 500;
}
.dropdown-activity .form-control {
  border: 1px solid #aaa;
  padding: 4px;
  border-radius: 0;
  margin: 0 6px;
  max-width: calc(100% - 12px);
}
.dropdown-activity.more-filter-dropdown .dropdown-toggle {
  background-color: #58cbf8;
  color: #fff;
  width: 120px;
}
.dropdown-activity.more-filter-dropdown .dropdown-toggle::after {
  display: none;
}
.more-filter-listing .dropdown-activity .dropdown-toggle {
  border: 1px solid #bcbcbc;
}
.age-range-filter {
  display: flex;
  align-items: center;
}
.more-filter-listing {
  padding: 17px 0 13px;
}
.more-filters {
  background-color: #fafafa;
  margin-bottom: 30px;
}
.age-range-filter label {
  color: #002240;
}
.age-range-filter .form-group label {
  font-size: 14px;
  color: #484848;
  font-weight: 500;
}
.more-filter-listing .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
}
.more-filter-listing .form-group {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-left: 25px;
}
.more-filter-listing .form-check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.more-filter-listing .checkmark::after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #008ec7;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  content: "";
  position: absolute;
}
.more-filter-listing .form-check:checked + .checkmark::after {
  opacity: 1;
}
.age-range-filter .form-group label {
  font-size: 14px;
}
.age-range-filter .form-group label img {
  width: 30px;
}
.age-range-filter .dropdown-activity .dropdown-toggle {
  width: 100px;
}
.age-range-filter > label {
  margin-right: 10px;
  font-weight: 600;
}
.more-filter-dropdown {
  margin-left: auto;
}
/* New Filter Design End */
.featured-section .owl-carousel.owl-theme .owl-nav {
  top: calc(50% - 40px);
}
.activity-cards-wrap {
  background-color: #fafafa;
  padding: 0 20px 15px;
  margin-bottom: 30px;
}
.find-activities-wrapper.spacing-top {
  margin-top: 87px;
  padding-top: 30px;
  min-height: calc(100vh - 180px);
  height: 100%;
}
.filter-page-title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
.filter-title-wrap button svg {
  color: #00aff4;
}
.featured-results .price-text strike {
  display: block;
  color: #dc3545;
}
.find-activities-wrapper {
  background-color: #f8f8f8;
}
.find-activities-wrapper .activity-card {
  margin: 0;
  overflow: hidden;
  border: 1px solid transparent;
  min-height: calc(100% - 30px);
}
.find-activities-wrapper .activity-card:hover {
  border: 1px solid #985da6;
}
.find-activities-wrapper .activity-media {
  max-height: 144px;
}
.featured-section .owl-carousel.owl-theme .owl-nav button.owl-prev {
  left: -10px;
}
.featured-section .owl-carousel.owl-theme .owl-nav button.owl-next {
  right: -20px;
}
.featured-section .owl-carousel.owl-theme .owl-nav button.owl-prev,
.featured-section .owl-carousel.owl-theme .owl-nav button.owl-next {
  height: 40px;
  width: 40px;
}
.featured-section .activity-title {
  font-size: 14px;
  margin-bottom: 2px;
}
.featured-section .activity-content p {
  font-size: 10px;
}
.rating-featured img {
  margin-inline-end: 4px;
}
.featured-section .activity-content p img,
.featured-section .owl-carousel .owl-item .activity-content p img {
  margin-inline-end: 5px;
}
.rating-featured {
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 6px;
}
.featured-section .offer-price {
  font-size: 12px;
  color: #e45c20;
  margin-inline-start: 8px;
}
.featured-section .activity-content p.actual-price {
  font-size: 12px;
}
.featured-section .footer-pricing {
  margin: 0px 0 5px 0;
  min-height: unset;
}
.activity-media .rating-featured {
  position: absolute;
  bottom: 12px;
  right: 0;
  background-color: #fff;
}
.featured-subtitle {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  opacity: 0.7;
}
.activity-cards-wrap .activity-card .activity-media .deal-flag img {
  width: 100%;
  height: auto;
}
.find-activities-wrapper .distant-section .activity-media {
  height: 100%;
}
.filter-group .btn-primary {
  color: #010317;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 6px 18px;
  border-radius: 12px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  margin-inline-end: 12px;
  margin-bottom: 12px;
}
.filter-group .btn-primary.active {
  border: 2px solid #fff;
  color: #fff;
  background: #00aff4;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
}
.filter-group .btn-primary:last-child {
  margin-right: 0;
}
.filter-btns-wrap {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 16px;
  padding-bottom: 30px;
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}
.filter-btns-wrap .filter-text {
  color: #000;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 700;
}
.radio-filter .form-check-input[type="radio"] {
  border-radius: 50%;
  border: 1px solid #f08146;
  background-color: #fff;
}
.form-check .form-check-label {
  font-size: 12px;
  line-height: 16px;
}
.radio-filter .form-check-input[type="radio"]:checked {
  background-image: url("../images/checked-circle.png");
  background-size: 8px;
}
.checkbox-filter .form-check-input[type="checkbox"] {
  border: 1px solid #f08146;
  background-color: #fff;
}
.checkbox-filter .form-check-input[type="checkbox"]:checked {
  background-color: #f08146;
}
.checkbox-filter .form-check-input[type="checkbox"]:focus,
.radio-filter .form-check-input[type="radio"]:focus {
  box-shadow: unset;
}
.scrolled.booking-sidebar {
  position: sticky;
  top: 110px;
  max-width: 396px;
  margin-bottom: 30px;
}
.activity-cards-wrap
  .infinite-scroll-component__outerdiv
  .infinite-scroll-component {
  overflow-x: hidden !important;
  padding-left: 10px;
}
.featured-section .owl-stage-outer {
  padding-left: 10px;
  padding-right: 10px;
}
.filter-group {
  border-bottom: 2px solid #efefef;
  padding-bottom: 6px;
}
.filter-group.event-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.filter-group.event-group .btn-primary {
  width: calc(50% - 6px);
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
}
.filter-group.event-group .btn-primary.active .inactive-icon,
.filter-group.event-group .btn-primary .active-icon {
  display: none;
}
.filter-group.event-group .btn-primary.active .active-icon {
  display: inline-block;
}
.distant-section .activity-media a img {
  aspect-ratio: 1.2 / 1;
}
.filter-btns-wrap .form-select {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  background-image: url("../images/chevron-blue-down.png");
  background-size: 11px 7px;
  width: 100%;
  margin-left: 0;
  font-size: 12px;
  line-height: 19px;
}
.filter-sidebar-wrap .multi-range-slider {
  border: medium none;
  border-radius: 0px;
  box-shadow: unset;
  margin-top: 30px;
  max-width: 94%;
}
.age-range-label {
  margin: 0 0 16px 0;
  align-items: center;
  position: relative;
  padding-top: 16px;
}
.filter-sidebar-wrap .age-range-label::after {
  position: absolute;
  left: -16px;
  top: 0;
  background-color: #848484;
  content: "";
  height: 1px;
  width: calc(100% + 32px);
  opacity: 0.1;
}
.age-range-label p {
  font-size: 10px;
  margin: 0;
}
.age-range-label .filter-text {
  margin: 0;
}
.filter-sidebar-wrap .multi-range-slider .thumb::before {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: -6px -1px;
  background: #f08146;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.25);
}
.filter-sidebar-wrap .multi-range-slider .bar-right,
.filter-sidebar-wrap .multi-range-slider .bar-left {
  background-color: #e4e4e4;
  border-radius: 12px;
  box-shadow: unset;
  height: 4px;
  padding: 0;
}
.filter-sidebar-wrap .multi-range-slider .bar-inner {
  background-color: #f08146;
  box-shadow: unset;
  border: medium none;
}
.filter-sidebar-wrap .multi-range-slider .ruler {
  display: none;
}
.filter-sidebar-wrap .multi-range-slider .label {
  font-size: 11px;
  color: #161819;
}
.filter-sidebar-wrap .multi-range-slider .caption {
  display: inline-block;
  left: -25px;
  bottom: 44px;
}
.filter-sidebar-wrap .multi-range-slider .caption span {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  background-image: url("./../images/range-icon-new.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 64px;
  height: 52px;
  background-color: transparent;
  box-shadow: unset;
  padding: 7px;
}
.sitemap-section {
  display: inline-block;
  width: 100%;
  padding-top: 56px;
}
.sitemap-section h2 {
  font-size: 24px;
  line-height: 26px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c5c5c5;
  color: #484848;
  font-weight: 600;
  margin-bottom: 0px;
}
.sitemap-section ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  padding-top: 21px;
}
.sitemap-section li {
  font-size: 14px;
  line-height: 21px;
  margin-top: 0px;
}
.sitemap-section ul li a {
  font-size: 17px;
  line-height: 28px;
  color: #00aeef;
  vertical-align: middle;
}
.sitemap-section ul.inner-ul {
  padding-top: 0;
  margin-top: 0;
  padding-left: 19px;
  list-style-type: square;
  list-style-position: inside;
  color: #00aeef;
  margin-bottom: 0px;
}
.sitemap-section ul.outer-ul h4 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 5px;
}
.sitemap-section ul.inner-ul li {
  list-style: square;
}
.filter-btns-wrap .form-select::after {
  display: none;
}
.filter-sidebar-wrap .dropdown-activity::after {
  position: absolute;
  left: -16px;
  top: 0;
  background-color: #848484;
  content: "";
  height: 1px;
  width: calc(100% + 32px);
  opacity: 0.1;
}
.filter-sidebar-wrap .dropdown-activity {
  padding-top: 1px;
  margin-top: 16px;
}
.filter-btns-wrap .form-select:hover,
.filter-btns-wrap .form-select:active,
.filter-btns-wrap .form-select:focus {
  box-shadow: unset;
  outline: 0 none;
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  color: #666;
}
/* Filter Page Design CSS End */
/* Paymetn Gateway CSS Start */
.payment-methods-wrapper {
  max-width: 600px;
  margin-inline: auto;
  margin-bottom: 30px;
  width: 90%;
}
.payment-options-list .card {
  background-color: #eee;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  margin-top: 30px;
}
.payment-options-list .card img {
  max-width: 90px;
}
.payment-options-list .card .card-body {
  padding: 0 0 0 15px;
}
.card-body .btn-primary {
  background-color: #06749f;
  padding: 4px 24px;
}
.confirm-booking-wrapper .frame--activated {
  border: 1px solid #bcbcbc;
  background-color: #fff;
  color: #666;
  text-align: left;
  font-weight: 500;
  border-radius: 8px;
  margin-bottom: 20px;
  max-height: 38px;
  padding-inline: 10px;
}
.confirm-booking-wrapper .frame--activated iframe {
  height: 100%;
  position: relative;
  max-height: 36px;
}
/* Paymetn Gateway CSS End */
/* Calender CSS start */
.wrapper .react-datepicker-popper {
  position: fixed !important;
  width: 100%;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.wrapper .react-datepicker-popper .react-datepicker {
  width: 100%;
  height: auto;
  max-width: 500px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 40px);
  padding-bottom: 20px;
  overflow: auto;
}
.wrapper .react-datepicker-popper .react-datepicker::-webkit-scrollbar {
  display: none;
}
.wrapper .react-datepicker__month-container {
  width: 100%;
  height: 100%;
}
.wrapper .react-datepicker__triangle {
  display: none;
}
.wrapper .react-datepicker .react-datepicker__day {
  padding: 24px 23px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
}
.wrapper .react-datepicker__day-name {
  padding: 17px 17px;
  width: 62px;
  height: 62px;
}
.wrapper .react-datepicker .react-datepicker__header {
  background-color: #fff;
  border: 1px solid #fff;
  padding-top: 30px;
}
.wrapper .react-datepicker .react-datepicker__navigation--previous {
  left: 20px;
  top: 25px;
}
.wrapper .react-datepicker .react-datepicker__navigation--next {
  right: 20px;
  top: 25px;
}
.booking-card {
  border: dashed 2px #e9e9e9;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  display: flex;
}
.booking-content-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.booking-content {
  width: 100%;
  max-width: calc(100% - 156px);
  padding-left: 20px;
}
.booking-media img {
  max-width: 156px;
  height: 132px;
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
}
.booking-page-detail-content .booking-media img {
  max-width: 100%;
  height: auto;
}
.confirm-booking-page-content .booking-media img {
  max-width: unset;
  height: auto;
}
.booking-content .tab-title-inner {
  color: #201c1d;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.time-text {
  color: #666;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.time-text img {
  margin-inline-end: 5px;
}
.price-text {
  color: #e45c20;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  /* margin: 10px 0 0 0; */
}
.booking-status span {
  color: #201c1d;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  opacity: 0.7;
}
.payment-status {
  color: #201c1d;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
}
.booking-by-btn.btn {
  background-color: #e1f0f6;
  width: 100%;
  color: #201c1d;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px 15px;
  margin-top: 8px;
}
.booking-tab-title {
  color: #848484;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 24px;
}
.bookings-wrap {
  max-width: 720px;
}
.my-bookings-content {
  margin-bottom: 25px;
}
.booking-card + .booking-card {
  margin-top: 22px;
}
.categories-listing {
  margin-bottom: 40px;
}
.categories-listing .item {
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.categories-card {
  max-width: 100%;
  border-width: 3px;
  border-color: #fff;
  margin: 0;
  border: 3px solid #fff;
  border-radius: 12px;
  padding: 15px 20px;
  transform: translateY(5px);
  padding-right: 10px;
  box-shadow: 0 8.53846px 13.0769px rgba(0, 0, 0, 0.12);
  transition: 0.3s;
}
.categories-card img {
  height: 90px;
  object-fit: contain;
  margin-left: auto;
  display: block;
  transform: translateY(5px);
  transition: 0.3s;
}
.categories-card:hover,
.categories-card:hover img {
  transform: translateY(-5px);
  transition: 0.3s;
}
.categories-listing .col-6:first-child .categories-card,
.categories-listing .col-6:nth-child(5) .categories-card,
.categories-listing .col-6:nth-child(6) .categories-card,
.categories-listing .col-6:nth-child(15) .categories-card,
.categories-listing .col-6:nth-child(8) .categories-card,
.categories-listing .col-6:nth-child(10) .categories-card {
  background-color: #e2f6fe;
}
.categories-listing .col-6:nth-child(2) .categories-card,
.categories-listing .col-6:nth-child(7) .categories-card,
.categories-listing .col-6:nth-child(13) .categories-card,
.categories-listing .col-6:nth-child(16) .categories-card,
.categories-listing .col-6:nth-child(9) .categories-card {
  background-color: #fae7ff;
}
.categories-listing .col-6:nth-child(3) .categories-card,
.categories-listing .col-6:nth-child(11) .categories-card,
.categories-listing .col-6:nth-child(18) .categories-card,
.categories-listing .col-6:last-child .categories-card {
  background-color: #dcf5e6;
}
.categories-listing .col-6:nth-child(4) .categories-card,
.categories-listing .col-6:nth-child(12) .categories-card,
.categories-listing .col-6:nth-child(17) .categories-card,
.categories-listing .col-6:nth-child(14) .categories-card {
  background-color: #f8f1d8;
}
.wallet-recored {
  border-radius: 12px;
  background-color: #5dcb91;
  padding: 16px 24px;
  background-image: url("../images/wallet-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.wallet-total {
  display: flex;
  justify-content: space-between;
}
.wallet-left p {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
.wallet-left .wallet-amount {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin: 5px 0 0 0;
}
.expiring-amount {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #e9e9e9;
  background: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  padding: 16px;
  justify-content: space-between;
  margin-bottom: 30px;
}
.amount-expire {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.text-orange {
  color: #f05355;
  font-weight: 600;
}
.amount-text {
  color: #000;
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0 0;
}
.wallet-voucher .form-control {
  border: 1px solid #efefef;
}
.wallet-voucher .form-group {
  position: relative;
}
.wallet-voucher .form-control::placeholder {
  font-size: 12px !important;
}
.wallet-voucher .btn-primary {
  border-radius: 8px;
  background: linear-gradient(264deg, #56bee7 -1.13%, #0096d1 88.5%);
  border: medium none;
  position: absolute;
  right: 6px;
  top: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 4px 14px;
}
.wallet-voucher .btn-primary:hover,
.wallet-voucher .btn-primary:active,
.wallet-voucher .btn-primary:focus {
  outline: 0 none;
  box-shadow: unset;
}
.wallet-history {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 24px 30px;
  margin-bottom: 30px;
}
.my-bookings-page.wallet-history {
  min-height: calc(100vh - 180px);
}
.wallet-history .recent-title {
  color: #201c1d;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}
.wallet-history .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.wallet-history .filter-btn {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  display: flex;
  align-items: center;
}
.wallet-history .filter-btn:hover,
.wallet-history .filter-btn:active,
.wallet-history .filter-btn:focus {
  outline: 0 none;
  box-shadow: unset;
  background-color: #fff;
}
.wallet-history .form-control {
  background-color: #f8f8f8;
  padding-left: 35px;
  max-width: calc(100% - 100px);
  border-radius: 12px;
}
.wallet-history .icon-group img.icon-left {
  top: 50%;
  transform: translate(0, -50%);
}
.wallet-history .form-control::placeholder {
  color: #010317;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.5;
}
.wallet-history .icon-group img {
  left: 10px;
  top: 18px;
}
.filter-btn img {
  position: static;
  margin-inline-end: 3px;
}
.transaction-content {
  display: flex;
  justify-content: space-between;
}
.transaction-date {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  opacity: 0.5;
  margin: 0;
  margin-bottom: 6px;
}
.transaction-desc p {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.transaction-desc span {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.56;
  padding-left: 30px;
}
.debit-amout {
  text-align: right;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}
.avail-bal {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  opacity: 0.56;
}
.transaction-desc p img {
  margin-inline-end: 5px;
}
.each-transaction {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.each-transaction:last-child {
  margin-bottom: 0px;
}
h3.booking-title {
  color: #201c1d;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-transform: capitalize;
}
.bookings-title-wrap p {
  color: #666;
  font-size: 18px;
  line-height: 1.22;
}
.no-data-found-wrapper {
  width: 100%;
  height: auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 0;
  border-radius: 5px;
  background-color: #fff;
}
.no-data-found-wrapper p {
  padding: 18px 15px;
  margin: 0;
  text-align: center;
  letter-spacing: 0.01px;
  line-height: 1.5;
  font-size: 20px;
  color: #484848;
  font-weight: 600;
}
.filter-dropdown-menu.dropdown-menu {
  background-color: #fff;
  z-index: 999;
  width: 430px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%) !important;
  position: fixed !important;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  transition: transform 0.3s ease-in-out;
  left: unset !important;
  padding: 1rem;
  right: 0 !important;
  top: 0 !important;
  padding: 0;
  border-radius: 0;
}
.filter-dropdown-menu.dropdown-menu.show {
  transform: unset !important;
}
.filter-dropdown-menu .dropdown-item:hover,
.filter-dropdown-menu .dropdown-item:active,
.filter-dropdown-menu .dropdown-item:focus {
  outline: 0 none;
  box-shadow: unset;
  background-color: transparent;
  color: #000;
}
.filter-dropdown-menu .filter-title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  padding: 40px 0 16px 30px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
.filter-type p {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  opacity: 0.7;
  margin: 20px 0 5px 0;
}
.wallet-filters .filter-type {
  padding: 0 30px;
  min-height: calc(100vh - 210px);
  overflow-y: auto;
}
.filter-listing {
  display: flex;
  flex-wrap: wrap;
}
.filter-listing .form-group {
  margin: 0;
  position: relative;
}
.filter-listing .form-group + .form-group {
  margin-inline-start: 6px;
}
.filter-listing .form-group .form-check {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  position: absolute;
}
.wallet-filters {
  height: 100vh;
}
.filter-listing .form-group label {
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 12px;
  text-transform: capitalize;
  cursor: pointer;
}
.filter-listing .form-group .form-check.active + label {
  border-radius: 12px;
  border: 1px solid #fff;
  background: #00aff4;
  color: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
}
.filter-footer {
  padding: 30px;
  display: flex;
  justify-content: center;
}
.filter-footer .btn {
  border-radius: 12px;
  border: 2px solid #00aff4;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin: 0 6px;
  width: 175px;
}
.filter-footer .filter-outline {
  color: #00aff4;
}
.filter-footer .filter-primary {
  color: #fff;
  background-color: #00aff4;
}
.handpicked-card .activity-card .activity-content,
.trending-carousel .activity-card .activity-content {
  padding: 12px 16px;
}
.handpicked-card .activity-card .activity-content .location,
.trending-carousel .activity-card .activity-content .location {
  margin-top: 8px;
}
.handpicked-card .activity-card .activity-content .footer-pricing,
.trending-carousel .activity-card .activity-content .footer-pricing {
  min-height: fit-content;
  margin-top: 14px;
  margin-bottom: 0;
}
.handpicked-card
  .activity-card
  .activity-content
  .footer-pricing
  .price-text
  .trending-carousel
  .activity-card
  .activity-content
  .footer-pricing
  .price-text {
  margin-top: 0;
}
.handpicked-card .activity-card .activity-content .activity-title,
.trending-carousel .activity-card .activity-content .activity-title {
  margin-top: 0;
}
.feeds-title-section .common-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 12px;
}
.feeds-title-section {
  margin-bottom: 40px;
}
.newsletter-section .newsletter-btn {
  margin-top: 39px;
}
/* Calender CSS End */

/* Activity Map css start  */
.map-location .activity-card {
  width: 280px;
}
.map-location .activity-card .activity-media .activity-image {
  max-height: 144px;
  width: 100%;
}
.map-location .activity-card .activity-content .activity-title {
  margin-top: 0;
  font-size: 14px;
}
.map-location .activity-card .price-details span:not(.discounted-price) {
  text-decoration: line-through;
}
.map-location .activity-card .price-details .discounted-price {
  color: #e45c20;
  margin-left: 5px;
}
.map-location .activity-content p {
  font-size: 9px;
}
.map-location .loactoin-pointer {
  cursor: pointer;
  width: 48px;
  height: 48px;
}
.map-location .dropdown-menu {
  --bs-dropdown-padding-y: 0;
  border-radius: 12px;
  border: none;
}
.map-location .dropdown-toggle.btn {
  background-color: transparent;
  border: none;
}
.map-location .dropdown-toggle.btn :where(:focus, :active, :hover) {
  background-color: transparent;
}
.map-location .activity-card:hover {
  border-color: transparent;
}
.map-location .activity-card .activity-media img {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
/* .map-location .activity-card{
  display: none;
}
.map-location .loactoin-pointer:hover .activity-card{
  display: block;
} */
.map-location .activity-content {
  padding: 8px 12px;
}
.review-user-media {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.review-user-media .user-avatar {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 48px;
  border: 1px solid #acacac;
}
.review-user-media .user-name {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}
.rating-star .rating {
  color: #201c1d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
  margin-left: 4px;
}
.activity-card.show-all
  .activity-content
  :where(.activity-title, .footer-pricing) {
  margin: 0;
}
.experience-detail-title-wrapper .book-now-wrap .dropdown-item {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* Activity Map css end  */
.modal-dialog .modal-body .account-edit {
  margin-top: 0;
}
.modal-dialog .modal-body .account-tab-content {
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}
.edit-kidz .modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.edit-profile-modal,
.modal-backdrop {
  display: none !important;
}
.show-edit-responsive {
  display: none !important;
}
.confirm-booking-header {
  margin-bottom: 10px;
}
.confirm-booking-header h3 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 0;
}
.confirm-booking-header p {
  color: #201c1d;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.confirm-booking-header img {
  width: 169.354px;
  height: 147.842px;
  flex-shrink: 0;
}
.confirm-booking-banner {
  background-image: url("../images/wallet-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px 30px;
  border-radius: 12px;
}
.confirm-booking-banner h3 {
  margin-bottom: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.confirm-booking-banner p {
  margin-bottom: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
.booking-service-section ul li img {
  width: 25px;
}
@media screen and (min-width: 768px) {
  .filter-sidebar-wrap .collapse:not(.show) {
    display: block;
  }

  .experience-detail-wrapper
    .detail-hover-wrap
    .each-detail-submedia:first-child {
    padding-left: 0;
    padding-right: 8px;
  }
  .experience-detail-wrapper
    .detail-hover-wrap
    .each-detail-submedia:last-child {
    padding-right: 0;
    padding-left: 8px;
  }
}
@media screen and (min-width: 768px) {
  .scrolled.booking-sidebar {
    max-height: calc(100vh - 140px);
    overflow-y: auto;
  }
}
@media screen and (min-width: 1366px) {
  .container {
    max-width: 1260px;
  }
  .find-activities-wrapper .distant-section .activity-media {
    max-height: 200px;
  }
  .activity-cards-wrap .activity-card .activity-media img {
    width: auto;
    max-width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .trending-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev {
    left: -50px;
  }
  .trending-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
    right: -50px;
  }
  .owl-collection.owl-carousel.owl-theme .owl-nav button.owl-prev {
    right: -60px;
  }
  .owl-collection.owl-carousel.owl-theme .owl-nav button.owl-next {
    right: -120px;
  }
  .kids-feed-value {
    padding-right: 15px;
  }
}
@media screen and (max-width: 1199px) {
  .main-header .navbar .navbar-nav .dropdown .nav-link.user-icon {
    margin-inline-start: 0;
  }

  .main-header .navbar .nav-link.user-icon span {
    background-color: #f08146;
    padding: 5px 6px;
    border-radius: 50%;
  }
}
@media screen and (min-width: 992px) {
  .hover-img img {
    height: 400px;
    overflow:hidden;
    object-fit: cover;
  }
  .filter-sidebar-wrap {
    position: sticky;
    top: 126px;
    max-width: 290px;
    width: 100%;
  }
  .video-carousel-wrapper {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 992px) {
  .banner-slider.section,
  .section.experience-section.handpicked-section,
  .section.trending-section.experience-section,
  .section.collection-section.experience-section,
  .section.blogs-section {
    height: fit-content !important;
  }
  .download-media img {
    max-width: 85%;
  }
  .kids-feed-value {
    padding-right: 10px;
  }
  .kids-feed-content {
    padding: 30px 10px;
  }
  .kids-feed-value .feed-values {
    font-size: 24px;
  }
  .kids-feed {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .kid-feed-title {
    font-size: 36px;
  }
  .tv-cards-carousel .owl-stage {
    padding-left: 0 !important;
  }
}
@media screen and (max-width: 991.98px) {
  .sharing-option .link-item:first-child {
    max-width: 100%;
  }
  .sharing-option {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .book-now-wrap .sharing-option {
    display: flex;
  }
  .experience-detail-title-wrapper .book-now-wrap {
    width: 100%;
  }
  .sharing-option .link-item + .link-item {
    margin-left: 0;
    margin-right: 16px;
  }
  .sharing-option .btn-primary {
    margin-inline-start: 0px;
  }
  .sharing-option .link-item {
    margin-bottom: 15px;
  }
  .experience-detail-title-wrapper .fun-sec {
    flex-wrap: wrap;
  }
  .fun-sec .book-now-wrap {
    margin-top: 20px;
  }
  .filter-sidebar-wrap {
    max-width: 100%;
  }
  .filter-sidebar-wrap .filter-title-wrap {
    margin-bottom: 30px;
  }
  .filter-sidebar-wrap .filter-title-wrap button:hover,
  .filter-sidebar-wrap .filter-title-wrap button:active,
  .filter-sidebar-wrap .filter-title-wrap button:focus,
  .filter-sidebar-wrap .filter-title-wrap button {
    border: medium none;
    background-color: transparent;
    outline: 0 none;
    box-shadow: unset;
  }
  .activity-cards-wrap {
    padding: 5px 0px;
  }
  .related-author p {
    font-size: 12px;
  }
  .myaccount-dropdown-menu.responsive .dropdown-toggle {
    text-align: start;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
@media screen and (max-width: 767.98px) {
  .common-title {
    font-size: 28px;
    line-height: 36px;
  }
  .confirm-booking-header {
    flex-direction: column-reverse;
  }
  .confirm-booking-header h3 {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  .confirm-booking-header p {
    text-align: center;
    font-size: 13px;
    line-height: 24px;
  }
  .distant-section .price-section {
    min-height: unset;
  }
  .show-edit-responsive {
    display: flex !important;
  }
  .hide-edit-responsive {
    display: none !important;
  }
  .edit-profile-modal .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .experience-detail-wrapper .booking-page-content {
    margin-top: 10px;
    border-radius: 0;
  }
  .experience-detail-title-wrapper .book-now-wrap {
    width: 100%;
  }
  .feed-media {
    margin-top: 40px;
  }
  .download-content {
    padding: 30px 0 15px 15px;
  }
  .app-download {
    margin-top: 60px;
  }
  .download-media {
    margin-top: 20px;
  }
  .download-info + .download-info {
    margin-inline-start: 7px;
  }
  .download-info img {
    max-width: 90%;
  }
  .owl-collection.owl-carousel.owl-theme .owl-nav button.owl-next {
    right: -50px;
  }
  .owl-collection.owl-carousel.owl-theme .owl-nav button.owl-prev {
    right: 0px;
  }
  .trending-carousel .owl-carousel.owl-theme .owl-nav button.owl-prev {
    left: 0px;
  }
  .trending-carousel .owl-carousel.owl-theme .owl-nav button.owl-next {
    right: 0px;
  }
  .trending-section {
    margin-top: 0px;
  }
  .collection-section {
    margin-top: 10px;
  }
  .trending-carousel .owl-stage,
  .collection-section .owl-stage,
  .categories-section .owl-stage {
    padding-left: 0 !important;
  }
  .collection-card .collection-media img {
    display: none;
  }
  .collection-card {
    padding: 10px;
    display: flex;
    /* min-height: 94px; */
  }
  .collection-title {
    -webkit-line-clamp: 3;
    margin: 0px auto;
    text-align: start;
  }
  .collection-gradient {
    background-size: 100% 100px;
    padding: 22px 10px 0px 10px;
  }
  .collection-gradient .owl-carousel {
    margin-top: -35px;
  }
  .collection-gradient .owl-carousel .owl-stage-outer,
  .categories-section .owl-carousel .owl-stage-outer {
    margin-right: -20px;
  }
  .newsletter-btn {
    max-width: 100%;
    min-width: 100%;
  }
  .collection-title ~ a {
    margin: 5px auto 0;
  }
  .trending-gradient {
    padding: 30px 0 0px 10px;
  }
  .book-now-wrap .link-item.responsive {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .book-now-wrap .nav-item.dropdown {
    width: 100%;
  }
  .book-now-wrap .link-item.responsive svg {
    margin-left: -25px;
    z-index: 2;
  }
  .experience-detail-wrapper .container .row > div {
    padding-left: 0;
    padding-right: 0;
  }
  .blog-pills {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
  }
  .blog-pills .nav-item .nav-link {
    padding: 10px;
    font-size: 14px;
  }
  .blog-title-wrap {
    width: 100%;
    margin-bottom: 20px;
  }
  .blog-title-section {
    flex-wrap: wrap;
  }
  /* .kids-feed-media {
    display: none;
  } */
  .kids-feed-content {
    width: 100%;
    padding: 15px 20px;
  }
  .kids-feed {
    flex-direction: column;
  }
  .kids-feed-media {
    height: 220px;
    width: 100%;
  }
  .votenow-modal .modal-title.h4 {
    font-size: 24px;
    line-height: 28px;
    color: #484848;
  }
  .awards-category-section .container {
    padding: 0;
  }
  .awards-category-section .container .row {
    margin-left: 0;
    margin-right: 0;
  }
  .blog-posted-by {
    flex-wrap: wrap;
  }
  .blog-detail-content .related-author p {
    margin-inline-end: 5px;
  }
  .blog-posted-by .dropdown-menu {
    justify-content: flex-start;
  }
  .about-content-wrap {
    margin-top: 20px;
  }
  .filter-title-wrap.title-wrap-right {
    margin-left: 0;
    margin-top: 30px;
  }
  .share-post {
    margin-bottom: 20px;
  }
  .related-blog-title a {
    min-height: unset;
  }
  .awards-banner {
    padding-top: 20px;
  }
  .winner-section {
    flex-wrap: wrap;
  }
  .winner-section-inner {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }
  .winner-section-inner::after {
    display: none;
  }
  .like-span {
    line-height: 1;
    padding: 4px 8px;
    min-width: 30px;
  }
  .preview-list li + li {
    margin-inline-start: 12px;
  }
  .tv-card-footer {
    padding: 14px 10px 14px 10px;
  }
  .scrolled.booking-sidebar {
    position: relative;
    top: unset;
    max-width: unset;
    margin-bottom: 30px;
  }
  .image-grid {
    grid-auto-rows: 120px;
  }
  .account-tab-content {
    padding: 20px 20px 20px 20px;
  }
  .how-app-work .wrapper .wrap {
    text-align: center;
    padding: 40px 60px;
  }
  .experience-section .title-section .common-title,
  .common-title {
    font-size: 20px;
  }
  .experience-section .title-section .title-wrap p {
    font-size: 14px;
  }
  .section.feeds-section .download-media {
    display: flex;
  }
  .section.feeds-section .download-media img {
    margin: auto;
  }
  .footer.main-footer .widget-text ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .widget-title {
    max-width: fit-content;
  }
  .footer.main-footer .store-logo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
  .footer.main-footer .store-logo a img {
    width: 100%;
    height: 46px;
  }
  .cashback-section .cashback-text p {
    font-size: 12px;
    line-height: normal;
  }
  .booking-sidebar .booking-wrapper {
    border: none;
    box-shadow: none;
    padding: 8px 0;
  }
  .filter-activities {
    background-color: #ecf4f8;
    margin-bottom: 21px;
  }
  .filter-activities .btn-filter {
    border: none;
    box-shadow: none;
    padding: auto 8px;
    max-height: unset;
    margin-bottom: 0;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .filter-activities .btn-filter.active-filter {
    background-color: #fff;
    border-radius: 0;
  }
  .trusted-companies {
    overflow: auto;
    gap: 1rem;
  }
  .trusted-companies li {
    flex-shrink: 0;
    max-width: unset;
  }
  .trusted-companies li img {
    height: 33px;
  }
  .login-button-group :where(.btn.btn-primary, .kep-login-facebook) {
    padding-left: 50px !important;
    padding-right: 15px !important;
  }
  .login-button-group :where(.btn.btn-primary, .kep-login-facebook) img {
    margin-right: 5px;
  }
  .awards-wrapper .awards-banner > span,
  .partner-media > a > span {
    width: auto !important;
    height: auto !important;
  }
  .journey-section {
    padding-top: 30px;
  }
  .award-patrner-section {
    padding: 30px 0;
  }
  .award-winners-section .journey-title {
    font-size: 24px;
    line-height: normal;
  }
  .award-winners-section {
    padding-top: 30px;
  }
  .award-patrner-section .nominate-button {
    padding: 5px 15px;
    font-size: 20px;
    width: 100%;
  }
  .categories-section .owl-carousel .owl-stage {
    width: 2000px !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
  }
  .categories-section .owl-carousel .owl-stage .owl-item {
    width: 150px !important;
  }
  .video-section {
    margin: 30px 0 !important;
  }
  .section.blogs-section {
    margin-top: 30px;
  }
  .journey-section .journey-title {
    font-size: 24px;
    line-height: normal;
  }
  .file-input-block .gallery .gallery-image {
    flex: 1;
    max-width: 100%;
  }
  .kids-feed-values .kids-feed-value {
    display: flex;
    justify-content: start;
    gap: 0.25rem;
    flex-direction: column;
  }
  .kids-feed-form {
    margin: 25px 0;
  }
  .get-in-touch-form .btn {
    width: 100%;
  }
  .blog-search-wrap {
    max-width: 100%;
  }
  .find-activities-wrapper .activity-media {
    max-height: unset;
  }
  .find-activities-wrapper .activity-media > img {
    height: 160px;
    width: 100%;
  }
  .find-activities-wrapper .activity-content {
    min-height: fit-content;
    padding-top: 0;
  }
  .featured-section .owl-carousel .owl-stage {
    padding-left: 0 !important;
  }
  .activity-content p.location {
    min-height: unset;
  }
  .award-winners-section.awards-category-section
    :where(.awart-winners-desc, .warning-text) {
    font-size: 16px;
    line-height: 20px;
  }
  .awards-category-section .reset-btn {
    max-width: 100%;
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 15px;
  }
  .awards-categories-accordion .card-header .btn span {
    font-size: 20px;
    line-height: 30px;
    padding-right: 30px;
  }
  .awards-categories-accordion .card-header .btn img {
    max-width: 80px;
  }
  .awards-categories-accordion .card {
    padding: 15px;
  }
  .awards-categories-accordion .card-header .btn {
    display: flex;
  }
  .awards-categories-accordion .card-header .btn::after {
    right: 0;
    width: 20px;
    height: 10px;
  }
  .nominee-lists {
    flex-direction: column;
  }
  .nominee-lists .nominee-details {
    max-width: 100%;
    width: 100%;
  }
  .nominee-lists .nominee-details:nth-child(odd) {
    padding-right: 0;
  }
  .nominee-lists .nominee-details input[type="radio"] + label {
    padding-left: 30px;
    font-size: 16px;
    line-height: 20px;
  }
  .more-infor-wrap {
    justify-content: space-between;
  }
  .more-infor-wrap .your-vote img {
    max-width: 60px;
    margin-left: 15px;
  }
  .more-infor-wrap .your-vote label {
    font-size: 16px;
    line-height: 20px;
    flex: 1;
  }
  .more-infor-wrap .your-vote label span {
    font-size: 16px;
    line-height: 20px;
    float: right;
  }
  .more-infor-wrap .your-vote {
    width: 100%;
  }
  .awards-categories-accordion .card .card-body {
    padding-bottom: 0;
  }
  .nominee-lists .nominee-details .nominee-radio-wrapper .nominee-logo {
    max-width: 60px;
  }
  .awards-categories-wrapper .btn-primary.nominate-button {
    width: 100%;
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    margin-top: 10px;
  }
  .experience-detail-title-wrapper {
    margin-top: 20px;
    margin-bottom: 8px;
  }
  .kidz-age-button button {
    width: 100%;
  }
  #kidsAge.tab-pane {
    margin-bottom: 16px;
  }

  .about-media img,
  .about-banner img {
    width: 75%;
  }
  .our-story-wrapper .about-banner {
    margin-bottom: 15px;
  }
  .handpicked-section .activity-card .activity-title {
    margin-top: 0;
  }
  .handpicked-section .activity-card .footer-pricing {
    margin-bottom: 0;
  }
  .feeds-title-section .common-title,
  .testimonial-section .common-title {
    font-size: 28px;
    font-weight: 700;
  }
  /* .distant-section .price-section{
    display: none !important;
} */
  .collection-section.experience-section .owl-carousel .owl-item {
    width: 130px !important;
  }
  .collection-section.experience-section
    .owl-carousel
    .owl-item
    .collection-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .testimonial-content .school-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
  .testimonial-content .programme-name {
    font-size: 18px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .testimonial-content .testimonial-text {
    font-size: 14px;
  }
  .experience-section.handpicked-section .tab-content .owl-item {
    width: 276px !important;
    padding: 0 8px;
  }
  .owl-collection {
    max-width: 100%;
  }
  .offer-text {
    max-width: 80%;
  }

  .review-carousel-card {
    border: 1px solid #efefef;
    border-radius: 16px;
    padding: 15px 12px;
  }
  .review-carousel-card .review-user-media .user-avatar {
    border-radius: 36px;
    border: 1px solid #acacac;
    width: 36px;
    height: 36px;
  }
  .review-by {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .review-section-content .book-btn {
    border-radius: 6px;
    border-color: transparent;
  }
  .review-carousel .owl-dots {
    display: none;
  }
  .login-button .btn-primary,
  .login-button-group .kep-login-facebook {
    justify-content: start;
    padding-left: 50px !important;
    padding-right: 15px !important;
  }
  .edit-profile-details {
    display: none;
  }
  .edit-profile-modal,
  .modal-backdrop {
    display: block !important;
  }
  /* .booking-page-content.booking-page-detail-content.booking-service-section ul {
    
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  } */
  .booking-page-content.booking-page-detail-content.booking-service-section
    ul
    li
    span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: start !important;
  }
  .payment-method .card {
    margin-bottom: 0;
  }
  /* .about-wrapper .go-back {
    margin-bottom: 20px;
  } */
  /* .experience-detail-title-wrapper .title-wrap .fun-list.nav {
    display: flex;
  }
  .experience-detail-title-wrapper .title-wrap .fun-list.nav li:last-child {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } */
  .poster-carousel-content.hover-img img {
    height: 120px;
  }
}
.footer-action .book-btn {
  border-radius: 6px;
  border: 1px solid #fff;
  background: #f08146;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 12px;
}
.phone-group-wrap.country-group input:focus {
  border-color: #dee2e6 !important;
}
.nav.stacked-pills.nav-pills .pill-icon {
  color: #a0a0a0;
  font-size: 19px;
}
.react-tel-input .flag-dropdown {
  border-right: 0 !important;
}
.country-group .react-tel-input .form-control {
  padding: 22px 15px;
}
.phone-group-wrap.country-group input.form-group:focus {
  border-color: #dee2e6 !important;
  outline: none;
}
.experience-carousel.blog-carousel .slick-slide li.nav-link.active {
  background-color: unset;
}
.experience-carousel.blog-carousel .slick-slide li.nav-link {
  padding-left: 0;
  padding-top: 0;
}
.experience-carousel.blog-carousel .slick-track {
  width: 100% !important;
}
.experience-carousel.blog-carousel .slick-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.experience-carousel.blog-carousel .slick-slide li.nav-link.active button {
  color: #00aff4;
  opacity: 1;
  font-weight: 700;
}
.booking-page-content.booking-page-detail-content .booking-title {
  font-size: 20px;
  margin-top: 26px;
  margin-bottom: 18px;
}
.booking-page-content.booking-page-detail-content
  :where(.fun-sec, .overview-section, .terms-section, ) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.booking-page-content.booking-page-detail-content .review-title {
  margin-bottom: 8px;
  font-size: 18px;
}
.booking-page-content.booking-page-detail-content .review-title span {
  font-size: 18px;
  font-weight: 500;
  display: inline;
}
.experience-detail-title-wrapper {
  padding: 20px 16px;
}
.email-modal .modal-content {
  width: 585px;
}

@media screen and (max-width: 575px) {
  .login-button {
    width: 100%;
  }
  .offer-deal {
    margin-bottom: 0;
  }
  .email-modal .modal-content {
    width: calc(100vw - 15px) !important;
  }
  .email-modal .email-title-section .kids-image {
    display: none;
  }
  .profile-edit.account-edit .save-btn-wrap {
    max-width: 100%;
    margin-top: auto;
  }
  .email-modal .email-title-section .email-title {
    max-width: 100%;
    margin-left: 0;
    font-size: 32px;
    margin-bottom: 37px;
  }

  .email-modal .modal-body {
    padding: 45px 24px 30px;
  }
  .email-modal .btn-primary {
    max-width: 100%;
    width: 100%;
  }
  .resend-content-mail .email-title {
    margin-bottom: 34px !important;
  }
  .login-button .btn-primary .fa {
    margin-inline-end: 15px;
  }
  .feed-media img {
    max-width: 90%;
    margin-inline: auto;
    display: block;
  }
  .owl-collection {
    max-width: 100%;
  }
  .owl-collection.owl-carousel.owl-theme .owl-nav button.owl-prev {
    right: 45px;
  }
  .owl-collection.owl-carousel.owl-theme .owl-nav button.owl-next {
    right: 0px;
  }
  /* .experience-carousel .owl-carousel {
    padding-inline: 25px;
} */
  .experience-carousel .owl-carousel .owl-item {
    width: auto !important;
  }
  .experience-carousel .owl-carousel .item li button {
    margin-left: 0 !important;
    margin-right: 16px;
  }

  .collection-section .collection-gradient .common-title {
    font-size: 20px;
  }
  .guest-content .email-title-section .kids-image {
    position: absolute;
    max-width: 150px;
    height: 121px;
    left: 20px;
    top: 20px;
    display: none;
  }
  .activity-card {
    margin: 0px 6px;
  }
  .distant-section .activity-card a {
    display: flex;
    align-items: center;
  }
  .distant-section .activity-card .activity-media {
    width: 115px;
    margin: 5px 0 5px 5px;
  }
  .distant-section .activity-card .activity-media .activity-image {
    width: 115px;
    height: 115px;
  }
  .distant-section .activity-card .activity-content .activity-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0;
  }
  .distant-section
    .activity-card
    .activity-content
    .activity-title
    .price-text {
    flex-direction: row-reverse !important;
  }
  .distant-section
    .activity-card
    .activity-content
    .activity-title
    .price-text
    :where(.offer-price, .actual-price) {
    font-size: 12px;
    margin: 0 2px 0;
  }
  .distant-section
    .activity-card
    .activity-content
    .activity-title
    .price-text
    :where(.offer-price) {
    color: #e45c20;
    font-weight: 500;
  }
  .distant-section .activity-card .activity-content {
    overflow: auto;
    flex-grow: 1;
  }
  .fun-sec .booking-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .booking-page-content.booking-page-detail-content .review-title {
    margin-bottom: 15px;
  }
  .distant-section .row > div {
    padding-right: 1.5rem;
  }

  .booking-wrapper .quantity-info {
    max-width: 114px;
  }
  .booking-wrapper .cashback-info {
    margin-top: 0;
  }
  .blog-detail-content .related-author {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .blog-detail-content .related-author p {
    width: 100%;
    margin-block: 0;
  }
  .related-author {
    flex-wrap: wrap;
  }
  .related-author p {
    margin: 0 0 10px 0;
  }
  .tv-cards-title-main {
    margin-top: 30px;
    font-size: 24px;
  }
  .wrapper .react-datepicker-popper .react-datepicker {
    max-width: 95%;
  }
  .wrapper .react-datepicker .react-datepicker__day,
  .wrapper .react-datepicker__day-name {
    padding: 0;
    width: 38px;
    min-width: 38px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper .react-datepicker .react-datepicker__header {
    padding-top: 10px;
  }
  .offer-deal-text p {
    font-size: 14px;
    padding-left: 0px;
    line-height: 22px;
  }
  .offer-deal-icon img {
    max-width: 70px;
  }
  /* .kids-feed-values {
    flex-wrap: wrap;
  } */
  .kids-feed-value,
  .kids-feed-value:nth-child(2) {
    width: 100%;
  }
  .kids-feed-value {
    display: flex;
    justify-content: center;
    /* border-right: medium none; */
  }

  .kids-feed-form .btn-primary {
    width: 110px;
  }
  .kids-feed-form .form-control {
    width: calc(100% - 110px);
  }
  .awards-category-section .kids-feed-content p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    color: #fff;
  }
  .video-section iframe {
    height: 240px;
    width: 100%;
  }
  .nominee-form input {
    max-width: 91%;
    flex: 0 0 91%;
  }
  .blog-detail-content .share-icons .dropdown-item {
    width: 30px;
    height: 30px;
  }
  .blog-detail-content .share-icons .dropdown-menu li {
    padding: 2px 2px;
  }
  .experience-detail-wrapper .sharing-option .link-item:first-child {
    width: 100%;
  }
  .guarantee-text-wrap {
    flex-wrap: wrap;
  }
  .guarantee-text-wrap .guarantee-text {
    /* width: 50%; */
    /* margin-bottom: 20px; */
  }
  .guarantee-text-wrap .guarantee-text img {
    width: 21.967px;
    height: 22px;
  }
  .guarantee-text span {
    text-align: center;
    font-size: 10px;
    line-height: 16px;
  }
  .cashback-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 4px;
  }
  .cashback-section {
    margin-bottom: 20px;
  }
  .modal {
    width: 100vw;
  }
  .sharing-option .link-item .dropdown .dropdown-toggle {
    white-space: wrap;
  }
  .sharing-option {
    display: flex;
  }
  .offer-deal .offer-deal-text em {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .sharing-option .link-item .dropdown .dropdown-toggle .dropdown-placeholder {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .kid-feed-title {
    font-size: 50px;
    line-height: 60px;
  }
  .kids-feed-content .kids-feed-value p {
    line-height: 18px;
    font-size: 13px;
  }
  .kids-feed-media {
    display: block;
  }
  .offer-text {
    max-width: 80%;
  }
  .myaccount-dropdown-menu.dropdown-menu {
    top: 30px;
    width: 350px;
  }
  .featured-section .activity-content {
    padding: 0px 16px;
    min-height: 75px;
  }
  .featured-section .activity-title {
    margin-top: 12px;
  }
  .venue-content-wrap {
    flex-direction: column;
  }
  .venue-content-wrap .venue-media {
    max-width: 100%;
  }
  .venue-content-wrap .venue-media img {
    width: 100%;
    height: 152px;
  }
  .venue-info-wrap {
    background-size: 100% 353px;
    margin: 16px 0;
  }
  .venue-content .fun-list:last-child {
    display: none;
  }
  .venue-content .fun-list li {
    margin-bottom: 0;
    font-size: 12px;
  }
  .my-bookings-content .booking-media img {
    width: 80px;
    height: 71.111px;
  }
  .booking-content-main {
    flex-direction: column;
  }
  .booking-content {
    max-width: 80%;
  }
  .time-text {
    font-size: 11px;
  }
  .time-text img {
    width: 16px;
  }
  .booking-content .tab-title-inner {
    font-size: 13px;
    line-height: 18px;
  }
  .price-text {
    font-size: 11px;
    line-height: 18px;
  }
  .booking-details .booking-status {
    font-size: 11px;
    line-height: 18px;
  }
  .booking-card {
    flex-wrap: wrap;
  }
  .use-ticket-by {
    width: 100%;
  }
  .sharing-content p {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  .sharing-content p strong {
    color: #201c1d;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
  .sharing-content .reviewed-star {
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
    padding: 8px;
    border-radius: 12px;
    border: 1px solid #efefef;
    margin-right: 10px;
  }
  .payment-title {
    font-size: 18px;
  }
  .go-back {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .booking-page-content .inner-meta label {
    font-size: 14px;
  }
  .booking-page-content {
    margin-bottom: 15px;
  }
}

.booking-wrapper .package-includes {
  flex-direction: column;
  align-items: start;
  gap: 0.25rem;
  width: unset;
}
@media screen and (max-width: 480px) {
  .cashback-icon img {
    width: 48px;
    height: 48px;
  }
  .cashback-text {
    padding-inline-start: 15px;
  }
  .review-title {
    flex-wrap: wrap;
    font-size: 16px;
  }
  .review-title span {
    display: block;
    margin-bottom: 20px;
  }
  .primary-blue-title {
    font-size: 26px;
    line-height: 34px;
  }
  .schedule-content::after {
    display: none;
  }
  .blog-detail-search .form-group .search-icon {
    padding: 6px 8px;
    max-width: 80px;
  }
  .image-grid {
    grid-auto-rows: 60px;
  }
  .how-app-work .wrapper .wrap {
    padding: 20px 60px;
  }
}
@media screen and (max-width: 350px) {
  .wrapper .react-datepicker .react-datepicker__day,
  .wrapper .react-datepicker__day-name {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}
