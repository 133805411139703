#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
#status{
  width: 89px;
  height: 89px;
  position: relative;
  background: rgba(255,255,255,0.13);
  animation-duration: 2.0s;
  animation-name: animSpin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
   
@keyframes animSpin{
  50%{
    transform: rotateZ(180deg) scale(.94);
  }
  100%{
    transform: rotateZ(360deg) scale(1);
  }
}

#status,
#status:before,
#status:after{
  border-radius: 50%;
}

#status:before,
#status:after{
  content: '';
  position: absolute;
  border: 8px solid transparent;
}

#status:before{
  width: 75%;
  height: 75%;
  background: transparent;
  left: 12.5%;
  top: 12.5%;
  border-left: 8px solid #00aff4;
  border-bottom: 8px solid #00aff4;
}

#status:after{
  width: 40%;
  height: 40%;
  left: 30%;
  top: 30%;
  border-right: 8px solid #F08146;
  border-left: 8px solid #F08146;
  border-bottom: 8px solid #F08146;
}