.attraction-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.attraction-content .attraction-link {
  border-radius: 6px;
  border: 1px solid #d3d3d3;
  color: #f08146;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 12px;
}
.review-title span {
  font-size: 18px;
  font-weight: 500;
  display: inline;
}
ul.offer-text-wrap li .offer-bg-icon {
  position: absolute;
  bottom: 0;
  right: 40px;
}
.book-now-wrap .sharing-option .map-details .location-content {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

/* responsive css start here  */
@media screen and (max-width: 575px) {
  .experience-detail-title-wrapper {
    padding: 20px 16px !important;
    margin: 0 !important;
  }
  .experience-detail-wrapper .go-back span {
    color: #000;
    font-size: 12px;
    font-weight: 600;
  }
  .experience-detail-wrapper .go-back img {
    margin-top: -3px;
  }
  .sharing-option .link-item .icon-left {
    filter: unset;
  }
  /* .experience-detail-wrapper .booking-page-content {
    padding: 20px 16px !important;
  } */
  /* .booking-page-content.booking-page-detail-content .review-title {
    margin-bottom: 12px !important;
  } */
  .experience-detail-wrapper .review-text p {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }
  .rate-ss span {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
  }

  .package-header .package-title {
    font-size: 14px;
    margin-bottom: 0;
  }
  .package-header p {
    font-size: 10px;
  }
  ul.fun-list.nav {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  ul.fun-list.nav li:first-child {
    padding-inline-end: 20px;
    white-space: nowrap;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  ul.fun-list.nav li img {
    width: 16px;
    height: 16px;
    padding-top: 1px;
  }
  ul.fun-list.nav li:nth-child(2) {
    height: 18px;
    padding-inline-end: 0;
  }
  ul.fun-list.nav li:nth-child(2) span {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }
  ul.fun-list.nav li:nth-child(2) span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .review-title {
    font-size: 16px !important;
  }
  .review-title span {
    font-size: 15px;
  }
  .lightbox-btn.show-all-btn {
    display: none !important;
  }
  .booking-page-content.booking-service-section ul li span {
    font-size: 12px;
    line-height: 16px;
  }
  .booking-service-section ul li img {
    width: 16px;
    height: 15.985px;
  }
  .package-amount .cashback-offer {
    font-size: 8px;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    justify-content: end;
  }
  .package-amount .cashback-offer img {
    width: 10px;
  }
  ul.offer-text-wrap li {
    margin-top: 0;
  }
  .other-offers-sec,
  .location-sec,
  .terms-condition-sec,
  .missing-info-sec,
  .review-section-content,
  .overview-sec {
    padding: 20px !important;
  }
  ul.offer-text-wrap li img:first-child {
    display: none;
  }
  .terms-condition-sec .review-text p {
    margin-bottom: 0;
  }
  .offer-text {
    background-color: rgba(240, 129, 70, 0.2);
  }
  .lightbox-rating-btn.pe-none {
    padding: 5px 8px;
  }
  .lightbox-rating-btn.pe-none img {
    margin-bottom: 1px;
  }
  .book-now-wrap .btn-primary {
    width: 30px;
    height: 30px;
  }
  .nav-link.btn.btn-primary img {
    width: 17px;
    height: 16px;
  }
  .review-text .read-more-text,
  .read-more-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }
  .cashback-section {
    position: relative;
  }
  .cashback-section .get-app-btn {
    position: absolute;
    border-radius: 6px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.44);
    color: #43b772;
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    top: 10px;
    right: 10px;
    padding: 1px 5px;
  }
  .detail-hover-wrap:has(.slick-dots) {
    margin-bottom: 30px;
  }
  .terms-condition-sec .review-title {
    margin-bottom: 10px !important;
  }
  .detail-hover-wrap .slick-dots li {
    margin: 0;
    width: 9px;
    height: 9px;
  }
  .detail-hover-wrap .slick-dots {
    margin: 0;
    bottom: -21px;
  }
  .detail-hover-wrap .slick-dots li button::before {
    font-size: 4px;
  }
  .detail-hover-wrap .slick-dots li.slick-active button::before {
    font-size: 8px;
  }
}
